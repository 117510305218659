import React, { useState, useEffect, act } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./FeaturedSolutionsComponent.module.scss";
import ArrowRight from "../../../../Assets/icons/Blue-Arrow.svg";
import { getProducts } from "../../../../ApiLayer/apiLayer";
import axios from "axios";
import axiosInstance from "../../../../util/AxiosInstance/AxiosInstance";
import ServerCard from "./Components/ServerCard";

const FeaturedSolutionsComponent = () => {
  const [activeDot, setActiveDot] = useState(0);
  const [productData, setProductData] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState({
    standard: [],
    gpuEnabled: [],
    storage: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Data for each category of servers
  const formattedServerData = [
    {
      pid: 101,
      customdata: {
        processor: "AMD Ryzen 7600 (6 Cores / 12 Threads)",
        memory: "64GB DDR5 RAM",
        storage: "1.92TB NVMe",
        network: "10Gbps Port / 50TB Outbound",
        ips: "/29 IPv4",
        gpu: "None",
        brandname: "AMD",
        location: "Datacenter - Ogden, Utah",
        model: "Ryzen",
        type: "Standard"
      },
      pricing: {
        USD: {
          monthly: "99.00"
        }
      },
      product_url: "https://clients.webnx.com/index.php?rp=/store/utah-instant-ryzen/ip-ogd-amd-ryzen-7600-64gb-ddr5-192tb-nvme",
      stocklevel: 1
    },
    {
      pid: 102,
      customdata: {
        processor: "AMD Ryzen 7900 (12 Cores / 24 Threads)",
        memory: "64GB DDR5 RAM",
        storage: "2 x 960GB PCIE4 NVMe",
        network: "10Gbps Port / 50TB Outbound",
        ips: "/29 IPv4",
        gpu: "None",
        brandname: "AMD",
        location: "Datacenter - Ogden, Utah",
        model: "Ryzen",
        type: "Standard"
      },
      pricing: {
        USD: {
          monthly: "139.00"
        }
      },
      product_url: "https://clients.webnx.com/cart.php?a=confproduct&i=15",
      stocklevel: 1
    },
    {
      pid: 201,
      customdata: {
        processor: "AMD Ryzen 7700 (8 Cores / 16 Threads)",
        memory: "64GB RAM",
        storage: "960GB Gen4 NVMe",
        network: "10Gbps Port / 50TB Outbound",
        ips: "/29 IPv4",
        gpu: "RTX 4060",
        brandname: "AMD",
        location: "Datacenter - Los Angeles, California",
        model: "Ryzen",
        type: "GPU Enabled"
      },
      pricing: {
        USD: {
          monthly: "159.00"
        }
      },
      product_url: "https://clients.webnx.com/cart.php?a=confproduct&i=24",
      stocklevel: 1
    },
    {
      pid: 202,
      customdata: {
        processor: "AMD EPYC 7443P (24 Cores / 48 Threads)",
        memory: "512GB RAM",
        storage: "2 x 3.84TB Gen4 NVMe",
        network: "10Gbps Port / 100TB Outbound",
        ips: "/29 IPv4",
        gpu: "4 x A100 80GB GPUs",
        brandname: "AMD",
        location: "Datacenter - Los Angeles, California",
        model: "EPYC",
        type: "GPU Enabled"
      },
      pricing: {
        USD: {
          monthly: "2999.00"
        }
      },
      product_url: "https://clients.webnx.com/index.php?rp=/store/los-angeles-instant-gpu-and-ai/gpu-la-dual-amd-epyc-7742-512gb-ram-15tb-nvme-7-x-nvidia-a40-gpu-10g",
      stocklevel: 1
    },
    {
      pid: 301,
      customdata: {
        processor: "Dual AMD EPYC 7742 (128 Cores / 256 Threads)",
        memory: "512GB DDR4 ECC RAM",
        storage: "2 x 15.4TB NVMe",
        network: "10Gbps Port / 50TB Outbound",
        ips: "/29 IPv4",
        gpu: "None",
        brandname: "AMD",
        location: "Datacenter - Ogden, Utah",
        model: "EPYC",
        type: "Storage"
      },
      pricing: {
        USD: {
          monthly: "759.00"
        }
      },
      product_url: "https://clients.webnx.com/cart.php?a=confproduct&i=4",
      stocklevel: 1
    },
    {
      pid: 302,
      customdata: {
        processor: "AMD EPYC 9354 (32 Cores / 64 Threads)",
        memory: "768GB DDR5 ECC RAM",
        storage: "3 x 2TB PCIE4 NVMe",
        network: "10Gbps Port / 50TB Outbound",
        ips: "/29 IPv4",
        gpu: "None",
        brandname: "AMD",
        location: "Datacenter - Ogden, Utah",
        model: "EPYC",
        type: "Storage"
      },
      pricing: {
        USD: {
          monthly: "899.00"
        }
      },
      product_url: "https://clients.webnx.com/cart.php?a=confproduct&i=5",
      stocklevel: 1
    },
    {
      pid: 401,
      customdata: {
        processor: "Your Choice of CPU",
        memory: "Your Choice of Memory",
        storage: "Your Choice of Storage",
        network: "Custom Bandwidth",
        ips: "",
        gpu: "Your Choice of GPU",
        brandname: "Custom",
        location: "Custom",
        model: "Custom",
        type: "Custom"
      },
      pricing: {
        USD: {
          monthly: "Custom Pricing"
        }
      },
      product_url: "/custom-dedicated-servers",
      stocklevel: 1
    },
    {
      pid: 402,
      customdata: {
        processor: "High-End Configurations",
        memory: "Tailored Storage",
        storage: "Priority Support",
        network: "Custom Bandwidth",
        ips: "",
        gpu: "Custom GPUs",
        brandname: "Custom",
        location: "Custom",
        model: "Custom",
        type: "Custom"
      },
      pricing: {
        USD: {
          monthly: "Custom Pricing"
        }
      },
      product_url: "/custom-dedicated-servers",
      stocklevel: 1
    }
  ];

  // Define static data for Custom Servers
  const customServersData = [
    {
      pid: 1000, // You can choose a unique identifier or leave a dummy number.
      customdata: {
        processor: "Your Choice of CPU",
        memory: "Your Choice of Memory ",
        storage: "Your Choice of Storage",
        network: "Custom Bandwidth",
        ips: "",
        gpu: "Your Choice of GPU",
        brandname: "",
        location: "",
        model: "",
        type: "Custom Servers"
      },
      pricing: {
        USD: {
          monthly: "Custom Pricing"
        }
      },
      product_url: "/custom-dedicated-servers",
      stocklevel: 0,
    },
    {
      pid: 1001,
      customdata: {
        processor: "High End Configurations",
        memory: "Tailored Memory",
        storage: "Tailored Storage",
        network: "Cusom Bandwidth",
        ips: "Custom IP Options",
        gpu: "High End GPU Options",
        brandname: "",
        location: "",
        model: "",
        type: "Custom Servers"
      },
      pricing: {
        USD: {
          monthly: "Custom Pricing"
        }
      },
      product_url: "/custom-dedicated-servers",
      stocklevel: 0,
    },
  ];




  //  ------------ **** DATA PROCESSING **** -----------
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await getProducts();
        if (data && data.products) {
          setProductData(data);

          // Create List of Gpu Enabled Products
          const gpuProducts = data.products.filter((product) => {
            const type = product.customdata?.type?.toLowerCase() || "";
            const gpu = product.customdata?.gpu?.toLowerCase() || "";

            return type.includes("gpu enabled") && gpu !== "none";
          });


          // Create List of Storage Server Products
          const storageProducts = data.products.filter((product) => {
            const type = product.customdata?.type?.toLowerCase() || "";
            return type.includes("storage");
          });

          const standardProducts = data.products.filter((product) => {
            const type = product.customdata?.type?.toLowerCase() || "";
            return type.includes("standard");
          });

          setFilteredProducts({
            gpuEnabled: gpuProducts.slice(0, 2),
            storage: storageProducts.slice(0, 2),
            standard: standardProducts.slice(0, 2),
          });
        }
      } catch (err) {
        console.error("Error fetching product data: ", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchProductData();
  }, []);

  // For the Slider, define 3 categories
  const categories = loading || error
    ? [
      { name: "Standard Servers", products: formattedServerData.filter(p => p.customdata.type.toLowerCase() === "standard") },
      { name: "GPU Enabled Servers", products: formattedServerData.filter(p => p.customdata.type.toLowerCase() === "gpu enabled" && p.customdata.gpu.toLowerCase() !== "none") },
      { name: "Storage Servers", products: formattedServerData.filter(p => p.customdata.type.toLowerCase() === "storage") },
      { name: "Custom Servers", products: customServersData },
    ]
    : [
      { name: "Standard Servers", products: filteredProducts.standard },
      { name: "GPU Enabled Servers", products: filteredProducts.gpuEnabled },
      { name: "Storage Servers", products: filteredProducts.storage },
      { name: "Custom Servers", products: customServersData },
    ];

  const filteredCategories = categories.filter((category) => category.products.length > 0);


  //  ------------ **** ANIMATIONS **** -----------
  // Set up the timer to switch the active dot every 7 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setActiveDot((prev) => (prev + 1) % filteredCategories.length);
    }, 7000);

    return () => clearInterval(timer);
  }, [filteredCategories.length]);

  // Animation variants for entry and exit of cards
  const cardContainerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  // Animation for the dots
  const dotVariants = {
    inactive: { scale: 1, backgroundColor: "#ccc" },
    active: { scale: 1.4, backgroundColor: "#07587F", borderColor: "#07587F" },
    hover: { scale: 1.2 },
  };

  // Hover animation for the cards
  const cardHover = {
    hover: {
      scale: 1.02,
      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
      transition: { duration: 0.3 },
    },
  };

  // Make sure dot index doesnt go out of range
  useEffect(() => {
    if (activeDot >= filteredCategories.length) {
      setActiveDot(0);
    }
  }, [filteredCategories, activeDot])



  return (
    <section className={styles.solutionsContainer}>
      <div className={styles.topRow}>
        <div>
          <h2 className={styles.header}>Featured Solutions</h2>
          <AnimatePresence mode="wait">
            <motion.p
              key={activeDot}
              className={styles.subHeader}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={cardContainerVariants}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              {filteredCategories[activeDot].name}
            </motion.p>
          </AnimatePresence>
        </div>
        <div className={styles.dotContainer}>
          {filteredCategories.map((cat, index) => (
            <motion.div
              key={index}
              className={`${styles.dot} ${activeDot === index ? styles.active : ""
                }`}
              variants={dotVariants}
              initial="inactive"
              animate={activeDot === index ? "active" : "inactive"}
              whileHover="hover"
              onClick={() => setActiveDot(index)}
              role="button"
              tabIndex={0}
              aria-label={`View ${cat.name}`}
              onKeyPress={(e) => e.key === "Enter" && setActiveDot(index)}
            />
          ))}
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeDot}
          className={`normalSpacing ${styles.solutionsCardContainer}`}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={cardContainerVariants}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          {filteredCategories[activeDot].products.map((product, index) => (
            <ServerCard key={index} product={product} />
          ))}
        </motion.div>
      </AnimatePresence>
    </section>
  );
};

export default FeaturedSolutionsComponent;
