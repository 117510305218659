import React from 'react';
import { Helmet } from 'react-helmet';

// Import Components
import TermsOfServiceComponent from './Components/TermsOfServiceComponent/TermsOfServiceComponent';

// Import SCSS
import styles from './TermsOfServicePage.module.scss';

const TermsOfServicePage = () => {
  return (
    <div className={styles.termsOfServicePage}>
      {/* React Helmet for SEO */}
      <Helmet>
        <title>Terms of Service | WebNX</title>
        <meta
          name="description"
          content="Explore WebNX's Terms of Service and Acceptable Usage Policy to understand our guidelines and your responsibilities when using our services."
        />
        <meta
          name="keywords"
          content="WebNX, Terms of Service, Acceptable Usage Policy, Legal Information, Hosting Guidelines"
        />
        <meta name="author" content="WebNX" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://yourwebsite.com/terms-of-service" />
      </Helmet>

      <main>
        <TermsOfServiceComponent />
      </main>
    </div>
  );
};

export default TermsOfServicePage;
