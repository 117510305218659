// Import Libraries
import React from "react";

// Import Styles
import styles from "./Footer.module.scss";

// Import Assets
import Logo from "../../../Assets/logo.png";

// Import Icons
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import BottomFooter from "./Components/BottomFooter";

const Footer = () => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.col1}>
          <img src={Logo} alt="WebNX Logo" />
          <p className={styles.text}>
            WebNX delivers reliable server hosting with 100% uptime,
            self-operated data centers, and 24/7 support. We offer flexible
            configurations and top-tier connectivity for secure, scalable
            solutions.
          </p>
        </div>

        <div className={styles.col2}>
          <h4>Fast Links</h4>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/standard-dedicated-servers">Dedicated Servers</a></li>
            <li><a href="/datacenter-locations-and-networks/utah">Networks & Locations</a></li>
            <li><a href="/about">Company</a></li>
            <li><a href="https://clients.webnx.com/index.php?rp=/login">Client Login</a></li>
            <li><a href="/service-level-agreement">Service Level Agreement</a></li>
            <li><a href="/terms-of-service">Terms of Service</a></li>
          </ul>
        </div>

        <div className={styles.col3}>
          <h4>Contact Info</h4>
          <div className={styles.contactItem}>
            <FaMapMarkerAlt className={styles.contactIcon} />
            <p className={styles.contactInfoText}>119 North 600 West, Bldg 3B, Ogden, UT 84404</p>
          </div>
          <div className={styles.contactItem}>
            <FaPhone className={styles.contactIcon} />
            <p className={styles.contactInfoText}>Main: 1-800-840-5996</p>
          </div>
          <div className={styles.contactItem}>
            <FaEnvelope className={styles.contactIcon} />
            <a href="mailto:support@webNX.com" className={styles.contactInfoText}>
              support@webNX.com
            </a>
          </div>

        </div>
      </div>
      <BottomFooter />
    </div>
  );
};

export default Footer;
