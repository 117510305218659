// Header.js

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { NavLink } from "react-router-dom";
import Logo from "../../../Assets/logo.png";
import UserIcon from "../../../Assets/icons/UserIcon.svg";
import DownIcon from "../../../Assets/icons/Down-Black.svg";
import styles from "./Header.module.scss";

const Header = ({ isMobileMenuOpen, toggleMobileMenu, hasHero = true }) => {
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  // Function to handle scroll
  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // Show header at the top of the page
    if (currentScrollY === 0) {
      setShowHeader(true);
      setHasScrolled(false); // Reset shadow at the top
    } else if (currentScrollY > lastScrollY) {
      // User scrolls down, hide header
      setShowHeader(false);
      setHasScrolled(true); // Apply shadow style after scrolling down
    } else if (currentScrollY < lastScrollY) {
      // User scrolls up, show header
      setShowHeader(true);
      setHasScrolled(true); // Apply shadow style after scrolling down
    }

    setLastScrollY(currentScrollY);
  };

  // Set up scroll listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  // Define SVG paths for the menu and close icons
  const menuPath = "M3 6h18M3 12h18M3 18h18"; // Path for menu (hamburger) icon
  const closePath = "M6 6l12 12M6 18L18 6"; // Path for close (X) icon

  return (
    <div>
      <motion.div
        className={styles.fullWidthBackground}
        style={{
          boxShadow: hasHero && !hasScrolled
            ? "10px 10px 10px 10px rgba(0, 0, 0, 0.7)" // Dark shadow only when hero is present and at top
            : "0px 4px 8px rgba(0, 0, 0, 0.2)" // Lighter shadow for all other cases
        }}
        initial={{ y: 0 }}
        animate={{ y: showHeader ? 0 : "-100%" }}
        transition={{ duration: 0.3 }}
      >
        <div className={styles.container}>
          {/* Desktop and Mobile Header */}
          <header className={styles.desktopNav}>
            <a href="/" title="WebNX Home">
              <img src={Logo} alt="WebNX Company Logo" loading="eager" />
            </a>
            <nav className={styles.mainNav} aria-label="Main Navigation">
              <ul className={styles.mainNavList}>
                <li className={styles.mainNavListItem}>
                  <a
                    href="/"
                    title="WebNX Home"
                    className={styles.mainNavListItemLink}
                  >
                    Home
                  </a>
                </li>

                {/* Dedicated Servers Dropdown on Hover */}
                <li
                  className={styles.mainNavListItem}
                  onMouseEnter={() => setHoveredMenu("dedicated")}
                  onMouseLeave={() => setHoveredMenu(null)}
                  role="presentation"
                >
                  <a
                    href="/all-dedicated-servers"
                    className={styles.mainNavListItemLink}
                    title="View All Dedicated Servers"
                  >
                    Dedicated Servers
                    <motion.img
                      src={DownIcon}
                      alt="Expand Dedicated Servers Menu"
                      className={styles.dropdownIndicator}
                      animate={{
                        rotate: hoveredMenu === "dedicated" ? 180 : 0,
                      }}
                      transition={{ duration: 0.3 }}
                    />
                  </a>
                  <AnimatePresence>
                    {hoveredMenu === "dedicated" && (
                      <motion.ul
                        className={styles.dropdownMenu}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                        role="menu"
                        aria-label="Dedicated Servers Submenu"
                      >
                        <li role="menuitem">
                          <a
                            href="/standard-dedicated-servers"
                            title="Standard Dedicated Servers"
                          >
                            Standard Servers
                          </a>
                        </li>
                        <li role="menuitem">
                          <a
                            href="/custom-dedicated-servers"
                            title="Custom Dedicated Servers"
                          >
                            Custom Servers
                          </a>
                        </li>
                        <li role="menuitem">
                          <a
                            href="/gpu-enabled-servers"
                            title="GPU Enabled Servers"
                          >
                            GPU Enabled Servers
                          </a>
                        </li>
                        <li role="menuitem">
                          <a href="/storage-servers" title="Storage Servers">
                            Storage Servers
                          </a>
                        </li>
                        <li role="menuitem">
                          <a href="/server-upgrades" title="Server Upgrades">
                            Server Upgrades
                          </a>
                        </li>
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </li>

                

                {/* Data Centers & Networks Link */}
                {/* <li className={styles.mainNavListItem}>
                  <a
                    href="/datacenter-locations-and-networks"
                    title="View WebNX Data Centers and Networks"
                    className={styles.mainNavListItemLink}
                  >
                    Data Centers & Networks
                  </a>
                </li> */}

                <li
                  className={styles.mainNavListItem}
                  onMouseEnter={() => setHoveredMenu("dataCenters")}
                  onMouseLeave={() => setHoveredMenu(null)}
                  role="presentation"
                >
                  <a
                    href="/datacenter-locations-and-networks/utah"
                    className={styles.mainNavListItemLink}
                    title="View WebNX Data Centers and Networks"
                    onClick={(e) => e.preventDefault()}
                    styles={{ cursor: 'mouse' }}
                  >
                    Data Centers & Networks
                    <motion.img
                      src={DownIcon}
                      alt="Expand Data Centers & Network Menu"
                      className={styles.dropdownIndicator}
                      animate={{
                        rotate: hoveredMenu === "dataCenters" ? 180 : 0,
                      }}
                      transition={{ duration: 0.3 }}
                    />
                  </a>
                  <AnimatePresence>
                    {hoveredMenu === "dataCenters" && (
                      <motion.ul
                        className={styles.dropdownMenu}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                        role="menu"
                        aria-label="Dedicated Servers Submenu"
                      >
                        <li role="menuitem">
                          <a
                            href="/datacenter-locations-and-networks/utah"
                            title="Utah Data enter & Network"
                          >
                            Utah 
                          </a>
                        </li>
                        <li role="menuitem">
                          <a
                            href="/datacenter-locations-and-networks/california"
                            title="Los Angeles Data Center & Network"
                          >
                            California
                          </a>
                        </li>
                        <li role="/menuitem">
                          <a
                            href="/datacenter-locations-and-networks/newyork"
                            title="GPU Enabled Servers"
                          >
                            New York 
                          </a>
                        </li>
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </li>

                {/* Colocation */}
                <li className={styles.mainNavListItem}>
                  <a
                    href="/colocation"
                    title="View Colocation Services"
                    className={styles.mainNavListItemLink}
                  >
                    Colocation
                  </a>
                </li>

                {/* Company Dropdown on Hover */}
                <li
                  className={styles.mainNavListItem}
                  onMouseEnter={() => setHoveredMenu("company")}
                  onMouseLeave={() => setHoveredMenu(null)}
                  role="presentation"
                >
                  <a
                    href="/about"
                    className={styles.mainNavListItemLink}
                    title="Learn About WebNX"
                  >
                    Company
                    <motion.img
                      src={DownIcon}
                      alt="Expand Company Menu"
                      className={styles.dropdownIndicator}
                      animate={{ rotate: hoveredMenu === "company" ? 180 : 0 }}
                      transition={{ duration: 0.3 }}
                    />
                  </a>
                  <AnimatePresence>
                    {hoveredMenu === "company" && (
                      <motion.ul
                        className={styles.dropdownMenu}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                        role="menu"
                        aria-label="Company Submenu"
                      >
                        <li role="menuitem">
                          <a href="/about" title="About WebNX">
                            About
                          </a>
                        </li>
                        <li role="menuitem">
                          <a href="/contact" title="Contact WebNX">
                            Contact
                          </a>
                        </li>
                        <li role="menuitem">
                          <a
                            href="/service-level-agreement"
                            title="WebNX Service Level Agreement"
                          >
                            Service Level Agreement
                          </a>
                        </li>
                        <li role="menuitem">
                          <a
                            href="/terms-of-service"
                            title="WebNX Terms of Service"
                          >
                            Terms of Service
                          </a>
                        </li>
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </li>
              </ul>
            </nav>

            {/* Desktop Client Login */}
            <nav className={styles.nav2} aria-label="Client Login Navigation">
              <ul className={styles.ul2}>
                <li>
                  <a
                    href="https://clients.webnx.com/index.php?rp=/login"
                    title="WebNX Client Login"
                  >
                    {/* <img
                      className={styles.clientLoginIcon}
                      src={UserIcon}
                      alt="User Account Icon"
                      loading="lazy"
                    /> */}
                   Login
                  </a>
                </li>
              </ul>
            </nav>

            {/* Hamburger Icon */}
            <motion.a
              onClick={toggleMobileMenu}
              className={styles.hamburgerMenu}
              aria-label={
                isMobileMenuOpen
                  ? "Close Navigation Menu"
                  : "Open Navigation Menu"
              }
            >
              <motion.svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <motion.path
                  d={isMobileMenuOpen ? closePath : menuPath}
                  fill="transparent"
                  strokeWidth="2"
                  stroke="currentColor"
                  strokeLinecap="round"
                  animate={{ d: isMobileMenuOpen ? closePath : menuPath }}
                  transition={{
                    duration: 0.3,
                    ease: "easeInOut",
                    type: "spring",
                    stiffness: 400,
                    damping: 20,
                  }}
                />
              </motion.svg>
            </motion.a>
          </header>

          {/* Mobile Navigation */}
          <AnimatePresence>
            {isMobileMenuOpen && (
              <motion.nav
                className={styles.mobileNav}
                initial={{ opacity: 0, y: 100, skewY: -5 }}
                animate={{ opacity: 1, y: 0, skewY: 0 }}
                exit={{ opacity: 0, y: 100, skewY: 5 }}
                transition={{
                  type: "spring",
                  stiffness: 100,
                  damping: 15,
                  mass: 0.5,
                  delay: 0.1,
                }}
                aria-label="Mobile Navigation"
              >
                <ul className={styles.mobileNavList}>
                  <li>
                    <a href="/" title="Home">
                      Home
                    </a>
                  </li>

                  {/* Dedicated Servers Mobile Dropdown */}
                  <li>
                    <div
                      className={styles.mobileNavItemWithArrow}
                      onClick={() =>
                        setHoveredMenu(
                          hoveredMenu === "dedicated" ? null : "dedicated"
                        )
                      }
                      role="button"
                      aria-expanded={hoveredMenu === "dedicated"}
                      aria-controls="dedicated-submenu"
                    >
                      <a
                        href="/all-dedicated-servers"
                        title="View All Dedicated Servers"
                      >
                        Dedicated Servers
                      </a>
                      <motion.img
                        src={DownIcon}
                        alt="Expand Dedicated Servers Menu"
                        className={`${styles.downArrow} ${
                          hoveredMenu === "dedicated" ? styles.rotated : ""
                        }`}
                        animate={{
                          rotate: hoveredMenu === "dedicated" ? 180 : 0,
                        }}
                        transition={{ duration: 0.3 }}
                      />
                    </div>
                    <AnimatePresence>
                      {hoveredMenu === "dedicated" && (
                        <motion.ul
                          id="dedicated-submenu"
                          className={styles.subMenuList}
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3 }}
                          role="menu"
                          aria-label="Dedicated Servers Submenu"
                        >
                          <li role="menuitem">
                            <a
                              href="/standard-dedicated-servers"
                              title="Standard Dedicated Servers"
                            >
                              Standard Servers
                            </a>
                          </li>
                          <li role="menuitem">
                            <a
                              href="/custom-dedicated-servers"
                              title="Custom Dedicated Servers"
                            >
                              Custom Servers
                            </a>
                          </li>
                          <li role="menuitem">
                            <a
                              href="/gpu-enabled-servers"
                              title="GPU Enabled Servers"
                            >
                              GPU Enabled Servers
                            </a>
                          </li>
                          <li role="menuitem">
                            <a href="/storage-servers" title="Storage Servers">
                              Storage Servers
                            </a>
                          </li>
                          <li role="menuitem">
                            <a href="/server-upgrades" title="Server Upgrades">
                              Server Upgrades
                            </a>
                          </li>
                        </motion.ul>
                      )}
                    </AnimatePresence>
                  </li>


                  {/* Data Centers and Locations Mobile Dropdown */}
                  <li>
                    <div
                      className={styles.mobileNavItemWithArrow}
                      onClick={() =>
                        setHoveredMenu(
                          hoveredMenu === "datacentersMobile" ? null : "datacentersMobile"
                        )
                      }
                      role="button"
                      aria-expanded={hoveredMenu === "datacentersMobile"}
                      aria-controls="datacentersMobile-submenu"
                    >
                      <a
                        href="#"
                        title="View All Dedicated Servers"
                        onClick={(e) => e.preventDefault()}
                        styles={{ cursor: 'default'}}
                      >
                        Data Centers & Locations
                      </a>
                      <motion.img
                        src={DownIcon}
                        alt="Expand Data Centers and Locations Menu"
                        className={`${styles.downArrow} ${
                          hoveredMenu === "datacentersMobile" ? styles.rotated : ""
                        }`}
                        animate={{
                          rotate: hoveredMenu === "datacentersMobile" ? 180 : 0,
                        }}
                        transition={{ duration: 0.3 }}
                      />
                    </div>
                    <AnimatePresence>
                      {hoveredMenu === "datacentersMobile" && (
                        <motion.ul
                          id="datacentersMobile"
                          className={styles.subMenuList}
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3 }}
                          role="menu"
                          aria-label="datacentersMobile submenu"
                        >
                          <li role="menuitem">
                            <a
                              href="/datacenter-locations-and-networks/utah"
                              title="Utah Datacenter and Location"
                            >
                              Utah
                            </a>
                          </li>
                          <li role="menuitem">
                            <a
                              href="/datacenter-locations-and-networks/california"
                              title="California Datacenter and Location"
                            >
                              California
                            </a>
                          </li>
                          <li role="menuitem">
                            <a
                              href="/datacenter-locations-and-networks/newyork"
                              title="New York Datacenter and Location"
                            >
                              New York
                            </a>
                          </li>
                        </motion.ul>
                      )}
                    </AnimatePresence>
                  </li>
                  {/* Company Mobile Dropdown */}
                  <li>
                    <div
                      className={styles.mobileNavItemWithArrow}
                      onClick={() =>
                        setHoveredMenu(
                          hoveredMenu === "company" ? null : "company"
                        )
                      }
                      role="button"
                      aria-expanded={hoveredMenu === "company"}
                      aria-controls="company-submenu"
                    >
                      <a
                        href="/about"
                        title="Learn About WebNX"
                        className={styles.company}
                      >
                        Company
                      </a>
                      <motion.img
                        src={DownIcon}
                        alt="Expand Company Menu"
                        className={`${styles.downArrow} ${
                          hoveredMenu === "company" ? styles.rotated : ""
                        }`}
                        animate={{
                          rotate: hoveredMenu === "company" ? 180 : 0,
                        }}
                        transition={{ duration: 0.3 }}
                      />
                    </div>
                    <AnimatePresence>
                      {hoveredMenu === "company" && (
                        <motion.ul
                          id="company-submenu"
                          className={styles.subMenuList}
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3 }}
                          role="menu"
                          aria-label="Company Submenu"
                        >
                          <li role="menuitem">
                            <a href="/about" title="About WebNX">
                              About
                            </a>
                          </li>
                          <li role="menuitem">
                            <a href="/contact" title="Contact WebNX">
                              Contact
                            </a>
                          </li>
                          <li role="menuitem">
                            <a
                              href="/service-level-agreement"
                              title="WebNX Service Level Agreement"
                            >
                              Service Level Agreement
                            </a>
                          </li>
                          <li role="menuitem">
                            <a
                              href="/terms-of-service"
                              title="WebNX Terms of Service"
                            >
                              Terms of Service
                            </a>
                          </li>
                        </motion.ul>
                      )}
                    </AnimatePresence>
                  </li>

                  {/* Data Centers & Networks Link in Mobile Menu */}
                  <li>
                    <a
                      href="/datacenter-locations-and-networks/utah"
                      title="View WebNX Data Centers and Networks"
                    >
                      Data Centers & Networks
                    </a>
                  </li>

                  {/*Colocation Link in Mobile Menu */}
                  <li>
                    <a
                      href="/colocation"
                      title="View Colocation Services"
                    >
                      Colocation
                    </a>
                  </li>

                  {/* Client Login */}
                  <li>
                    <a
                      href="https://clients.webnx.com/index.php?rp=/login"
                      title="Client Login"
                    >
                      Client Login
                    </a>
                  </li>
                </ul>
              </motion.nav>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </div>
  );
};

export default Header;
