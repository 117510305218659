// Import Libraries
import React from "react";
import { Helmet } from "react-helmet";

// Import Components
import HeroComponent from "../../Layout/Components/HeroComponent/HeroComponent";
import CustomServerQuoteForm from "./Components/CustomServerQuoteForm/CustomServerQuoteForm";
import ServerStandardsComponent from "../../Components/ServerStandardsComponent/ServerStandardsComponent";
import DedicatedServerLocations from "../DedicatedServersPage/Components/DedicatedServerLocations/DedicatedServerLocations";
import TestNetworkSpeedsComponent from "../DedicatedServersPage/Components/TestNetworkSpeedsComponent/TestNetworkSpeedsComponent";
import ServerPageIntro from "../../Components/ServerPageIntro/ServerPageIntro";

// Import Assets
import CustomServer from "../../Assets/Custom_Server.png";

// Import Animation
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

const CustomDedicatedServersPage = () => {
  return (
    <div>
      {/* Helmet for Metadata */}
      <Helmet>
        <title>
          Custom Dedicated Servers | Tailored Server Solutions by WebNX
        </title>
        <meta
          name="description"
          content="WebNX Custom Dedicated Servers are tailored to fit every unique business need. Enjoy high-compute power, GPU integration, and premium hardware with global connectivity."
        />
        <meta
          name="keywords"
          content="custom dedicated servers, tailored server solutions, GPU integration, high-compute servers, WebNX hosting"
        />
        <meta property="og:title" content="Custom Dedicated Servers | WebNX" />
        <meta
          property="og:description"
          content="Discover WebNX Custom Dedicated Servers for tailored solutions with high-compute power, GPU capabilities, and a global optimized network."
        />
        <meta
          property="og:image"
          content="https://webnx.com/assets/custom-server.jpg"
        />
        <meta
          property="og:url"
          content="https://webnx.com/custom-dedicated-servers"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://webnx.com/custom-dedicated-servers"
        />
      </Helmet>

      {/* Hero Section */}
      {/* <HeroComponent
        title="Custom Dedicated Servers"
        subtitle="Custom Solutions For Every Need. <br /> Unleash the Power of <br /> WebNX"
        buttonText1="Shop Servers Now"
        buttonLink1="#filterComponent"
        showButton1={true}
        buttonText2="More Information"
        buttonLink2="#moreInfo"
        showButton2={true}
      /> */}

      {/* Server Page Intro */}
      <FadeInOnScroll>
        <ServerPageIntro
          header="WebNX Custom Dedicated Servers"
          subheader="Tailored Solutions for Any Workload"
          introText="WebNX builds high-performance custom dedicated servers designed to meet your exact specifications. From single to quad CPU setups to GPU-optimized machines, we provide the flexibility you need for AI, rendering, scientific computing, and high-compute tasks. Our high-performance BGP Route Optimized network ensures low-latency, reliable connectivity worldwide."
          image={CustomServer}
          hideImageOnMobile={true}
          features={[
            {
              icon: "FaMicrochip",
              title: "Custom CPU Configurations",
              description: "Single, dual, and quad CPU setups for high-compute workloads."
            },
            {
              icon: "FaBolt",
              title: "GPU-Optimized Servers",
              description: "Integrate specialized GPUs for AI, rendering, and deep learning."
            },
            {
              icon: "FaHdd",
              title: "Premium Hardware",
              description: "Built with components from SuperMicro, Intel, AMD, and LSI."
            },
            {
              icon: "FaNetworkWired",
              title: "High-Performance Networking",
              description: "Optimized BGP routing for fast and reliable global connectivity."
            },
            {
              icon: "FaDatabase",
              title: "Flexible OS Support",
              description: "Compatible with CentOS, Debian, Ubuntu Server, and Windows Server."
            },
            {
              icon: "FaShieldAlt",
              title: "Reliable Security & Support",
              description: "24/7 support, SLA-backed reliability, and advanced security features."
            }
          ]}
        />

      </FadeInOnScroll>

      {/* Custom Server Quote Form */}
      <CustomServerQuoteForm />

      {/* Server Standards */}
      <FadeInOnScroll>
        <ServerStandardsComponent />
      </FadeInOnScroll>

      {/* Dedicated Server Locations */}
      <FadeInOnScroll>
        <DedicatedServerLocations />
      </FadeInOnScroll>

      {/* Test Network Speeds */}
      <FadeInOnScroll>
        <TestNetworkSpeedsComponent />
      </FadeInOnScroll>
    </div>
  );
};

export default CustomDedicatedServersPage;
