import React, { useState } from "react";

// Import SCSS
import styles from "./NetworkInfoComponent.module.scss";
import ImageComponent from "../ImageComponent/ImageComponent";

// Import Assets
import Datacenter1 from "../../../../Assets/datacenter1.png";
import Datacenter2 from "../../../../Assets/datacenter2.png";
import Datacenter3 from "../../../../Assets/datacenter3.png";
import Datacenter4 from "../../../../Assets/datacenter4.png";
import Datacenter5 from "../../../../Assets/datacenter5.png";

// Data for each location
const locationsData = {
  utah: {
    title: "Ogden, Utah Data Center and Network",
    description:
      "Explore WebNX's Ogden, Utah Data Center offering over 100,000 sqft of colocation space. Enjoy high-performance GBP Route Optimized networks and connections to global carriers.",
    datacenterInfo: `With over 100,000 sq. ft. of premium colocation space, WebNX provides scalable solutions from single-server colocation to private cages and multi-rack deployments. Our facility is designed for maximum reliability, featuring a true N+1 power infrastructure with dual grid utility feeds, N+1 UPS systems, and four diesel generators (and growing)—ensuring uninterrupted uptime for your critical infrastructure.`,
    networkInfo: {
      overview: `WebNX offers diverse connectivity options, with multiple onsite providers and the ability to access nearly any carrier from the Salt Lake City and Los Angeles (Coresite & Equinix) markets. In addition, our high-performance terabit Route Optimized network ensures low-latency, global connectivity, making WebNX Ogden an ideal choice for businesses that demand fast, reliable, and cost-effective networking solutions.`,
      features: [
        "Direct Uplinks: Connections to major carriers like Level3, NTT, Zayo, Telia, and China Unicom.",
        "Asia Optimization: Real-time route optimization with direct links to China Unicom and peering with numerous APAC ISPs.",
        "Capacity: Over 1Tbps total uplink capacity, featuring 100Gbps+ connections and multi-path 100G links to our highly peered, high-performance Los Angeles network.",
      ],
    },
    images: [Datacenter1, Datacenter2, Datacenter3, Datacenter4, Datacenter5],
  },
  california: {
    title: "Los Angeles, California Data Center and Network",
    description:
      "Explore WebNX's Ogden, Utah Data Center offering over 100,000 sqft of colocation space. Enjoy high-performance GBP Route Optimized networks and connections to global carriers.",
    datacenterInfo: `With over 100,000 sq. ft. of premium colocation space, WebNX provides scalable solutions from single-server colocation to private cages and multi-rack deployments. Our facility is designed for maximum reliability, featuring a true N+1 power infrastructure with dual grid utility feeds, N+1 UPS systems, and four diesel generators (and growing)—ensuring uninterrupted uptime for your critical infrastructure.`,
    networkInfo: {
      overview: `WebNX offers diverse connectivity options, with multiple onsite providers and the ability to access nearly any carrier from the Salt Lake City and Los Angeles (Coresite & Equinix) markets. In addition, our high-performance terabit Route Optimized network ensures low-latency, global connectivity, making WebNX Ogden an ideal choice for businesses that demand fast, reliable, and cost-effective networking solutions.`,
      features: [
        "Direct Uplinks: Connections to major carriers like Level3, NTT, Zayo, Telia, and China Unicom.",
        "Asia Optimization: Real-time route optimization with direct links to China Unicom and peering with numerous APAC ISPs.",
        "Capacity: Over 1Tbps total uplink capacity, featuring 100Gbps+ connections and multi-path 100G links to our highly peered, high-performance Los Angeles network.",
      ],
    },
    images: [Datacenter2, Datacenter3, Datacenter4, Datacenter5, Datacenter1],
  },
  newyork: {
    title: "Manhattan, New York Data Center and Network",
    description:
      "Explore WebNX's Ogden, Utah Data Center offering over 100,000 sqft of colocation space. Enjoy high-performance GBP Route Optimized networks and connections to global carriers.",
    datacenterInfo: `With over 100,000 sq. ft. of premium colocation space, WebNX provides scalable solutions from single-server colocation to private cages and multi-rack deployments. Our facility is designed for maximum reliability, featuring a true N+1 power infrastructure with dual grid utility feeds, N+1 UPS systems, and four diesel generators (and growing)—ensuring uninterrupted uptime for your critical infrastructure.`,
    networkInfo: {
      overview: `WebNX offers diverse connectivity options, with multiple onsite providers and the ability to access nearly any carrier from the Salt Lake City and Los Angeles (Coresite & Equinix) markets. In addition, our high-performance terabit Route Optimized network ensures low-latency, global connectivity, making WebNX Ogden an ideal choice for businesses that demand fast, reliable, and cost-effective networking solutions.`,
      features: [
        "Direct Uplinks: Connections to major carriers like Level3, NTT, Zayo, Telia, and China Unicom.",
        "Asia Optimization: Real-time route optimization with direct links to China Unicom and peering with numerous APAC ISPs.",
        "Capacity: Over 1Tbps total uplink capacity, featuring 100Gbps+ connections and multi-path 100G links to our highly peered, high-performance Los Angeles network.",
      ],
    },
    images: [Datacenter3, Datacenter4, Datacenter5, Datacenter1, Datacenter2],
  },
};

const NetworkInfoComponent = ({ location }) => {
  const { title, description, datacenterInfo, networkInfo, images } = locationsData[location];

  return (
    <div>
      <div className={styles.background}>
        {/* <div className={styles.selectBarContainer}>
          <nav
            className={styles.selectBar}
            aria-label="Data center location selector"
          >
            {Object.keys(locationsData).map((location) => {
              const locationLabels = {
                utah: "Utah",
                california: "California",
                newyork: "New York",
              };

              return (
                <p
                  key={location}
                  onClick={() => handleLocationChange(location)}
                  className={selectedLocation === location ? styles.active : ""}
                >
                  {locationLabels[location]}
                </p>
              );
            })}
          </nav>
        </div> */}
      </div>

      <section className={styles.background}>
        <div className={styles.container}>
          <h1 className={styles.title}>{title}</h1>
          <meta name="description" content={description} />
          <div className={styles.colContainer}>
            <article className={styles.col1}>
              <h2 className={styles.subTitle}>Datacenter Information</h2>
              <p className={styles.text}>{datacenterInfo}</p>
            </article>
            <aside className={styles.col2}>
              <h2 className={styles.subTitle}>Network Overview</h2>
              <p className={styles.text}>{networkInfo.overview}</p>
              <h3 className={styles.listTitle}>Key Network Features</h3>
              <ul className={styles.featureList}>
                {networkInfo.features.map((feature, index) => (
                  <li key={index} className={styles.featuredText}>
                    {feature}
                  </li>
                ))}
              </ul>
            </aside>
          </div>
        </div>
      </section>

      <ImageComponent images={images} />
    </div>
  );
};

export default NetworkInfoComponent;
