import React, { useState } from "react";
import styles from "./ColocationInfo.module.scss";
import {
  FaNetworkWired,
  FaBolt,
  FaBuilding,
  FaChevronDown,
  FaChevronUp,
  FaCheck,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FAQ_DATA_OGDEN = [
  {
    title: "Ogden Network",
    icon: <FaNetworkWired size={22} />,
    items: [
      "$10 Cross-Connects available to Zayo, Lumen (Level 3), Verizon (XO), and soon Syringa & UTOPIA, with additional provider options",
      "Cost-effective VLAN options for direct connections to Cogent, Arelion, GTT, and HE, plus extended cross-connects to providers in Salt Lake City",
      "Option to seamless connect to any provider at Coresite & Equinix Los Angeles",
      "High-performance, well-peered terabit network, supporting 10G, 25G, 40G, 100G, and 400G port options"
    ],
  },
  {
    title: "Ogden Power",
    icon: <FaBolt size={22} />,
    items: [
      "Full N+1 redundancy, including dual diverse utility uplinks",
      "250/500KW N+1 APC Symmetra PX UPS systems on both A and B power feeds",
      "4x Diesel backup generators for reliable power continuity",
      "Custom rack and cage power configurations available, supporting 120V, 208V, and 3-phase power options"
    ],
  },
  {
    title: "Ogden Facility",
    icon: <FaBuilding size={22} />,
    items: [
      "24/7 on-site staffing for security and support",
      "Over 100 high-resolution 4K cameras with offsite data storage",
      "Dual diverse fiber paths with terabit DWDM connectivity to major Salt Lake City carrier hotels",
      "Onsite 100G+ providers including Zayo, Lumen, Syringa, and Verizon",
      "Dual diverse 100G paths to our highly peered Los Angeles network at Coresite & Equinix",
      "Full loading dock and forklift access for seamless freight deliveries"
    ],
  },
];

const FAQ_DATA_LA = [
  {
    title: "LA Network",
    icon: <FaNetworkWired size={22} />,
    items: [
      "Default seamless connectivity through our well-peered terabit network, with 10G, 25G, 40G, 100G, and 400G port options available",
      "Option for direct cross-connects to any provider in the Los Angeles market, with onsite fiber access to Equinix LA3/LA4 and extended cross-connects to Coresite LA1/LA2",
      "Cost-effective direct VLAN options to Cogent, Arelion, GTT, HE, and more, eliminating cross-connect fees"
    ]
  },
  {
    title: "LA Power",
    icon: <FaBolt size={22} />,
    items: [
      "Full N+1 redundancy across all systems for maximum reliability",
      "A & B power feeds backed by two independent UPS systems",
      "10+ diesel generators, each with 2 megawatts of capacity",
      "Over 6 years of 100% uptime backed by our proven infrastructure"
    ]
  },
  {
    title: "LA Facility",
    icon: <FaBuilding size={22} />,
    items: [
      "Premium colocation at Equinix LA3 for top-tier infrastructure and connectivity",
      "Certified facility with SOC 1 Type II, SOC 2 Type II, NIST 800-53/FISMA High, and more",
      "Onsite WebNX office with dedicated WebNX staff for hands-on support",
      "Full loading dock and forklift access for seamless freight deliveries"
    ]    
  },
];

const ColocationInfo = () => {
  const [expanded, setExpanded] = useState(0);
  const [locationSelected, setLocationSelected] = useState("Ogden");

  const toggleFAQ = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className={styles.background}>
      <div className={styles.allContentContainer}>
        <div className={styles.dedicatedServersContainer}>
          <div className={styles.dedicatedServersLeft}>
            <h2 className={styles.dedicatedServersHeader}>
              Enterprise-Grade Colocation Services
            </h2>
            <h3 className={styles.dedicatedServersSubHeader}>
              Colocation Specs
            </h3>
            <p className={styles.dedicatedServersText}>
              WebNX provides colocation services in two state-of-the-art data centers located in Ogden, Utah, and Los Angeles, California. Our facilities offer secure, reliable, and scalable colocation solutions with redundant power, cooling, and network connectivity.
            </p>
            <Link to="/datacenter-locations-and-networks/utah" className={styles.here}>
              Click here to learn more about our data centers
            </Link>
          </div>
        </div>
        <div className={styles.selectorContainerContainer}>
          <div className={styles.selectorContainer}>
            <h2
              onClick={() => setLocationSelected("Ogden")}
              className={`${styles.ogden} ${
                locationSelected === "Ogden" ? styles.active : ""
              }`}
            >
              Ogden, Utah
            </h2>
            <h2
              onClick={() => setLocationSelected("LosAngeles")}
              className={`${styles.losangeles} ${
                locationSelected === "LosAngeles" ? styles.active : ""
              }`}
            >
              Los Angeles, CA
            </h2>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.colocationSpecs}>
            <div className={styles.info}>
              {(locationSelected === "Ogden" ? FAQ_DATA_OGDEN : FAQ_DATA_LA).map((faq, index) => (
                <div
                  key={index}
                  className={`${styles.faq} ${
                    expanded === index ? styles.expanded : ""
                  }`}
                  onClick={() => toggleFAQ(index)}
                >
                  <div className={styles.faqHeader}>
                    <div className={styles.leftContainer}>
                      <span className={styles.icon}>{faq.icon}</span>
                      <span className={styles.faqTitle}>{faq.title}</span>
                    </div>
                    <span
                      className={`${styles.arrowIndicator} ${
                        expanded === index ? styles.arrowOpen : ""
                      }`}
                    >
                      {expanded === index ? <FaChevronUp /> : <FaChevronDown />}
                    </span>
                  </div>
                  <ul className={styles.faqContent}>
                    {faq.items.map((item, i) => (
                      <li className={styles.listItem} key={i}>
                        <span className={styles.checkIcon}>
                          <FaCheck />
                        </span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColocationInfo;
