import axiosInstance from "../util/AxiosInstance/AxiosInstance";

export const sendColocationQuoteForm = async (formData, captchaToken) => {
    try {
        // Format the form data to match backend expectations
        const formattedData = {
            name: formData.name || "",
            email: formData.email || "",
            phone: formData.phone || "",
            companyName: formData.companyName || "",
            location: formData.location || "",
            cabinetSize: formData.cabinetSize || "",
            powerRequirements: formData.powerRequirements || "",
            networkSpeed: formData.networkSpeed || "",
            ipAddresses: formData.ipAddresses || "",
            crossConnects: formData.crossConnects || "",
            remoteHands: formData.remoteHands || "",
            networkRedundancy: formData.networkRedundancy || "",
            rackSpace: formData.rackSpace || "",
            cabinetType: formData.cabinetType || "",
            access: formData.access || "",
            backupPower: formData.backupPower || "",
            monitoring: formData.monitoring || "",
            dataBackup: formData.dataBackup || "",
            ddosProtection: formData.ddosProtection || "",
            additionalNotes: formData.additionalNotes || "",
            captchaToken: captchaToken
        };

        // Send the formatted data to the API endpoint
        const response = await axiosInstance.post("/contact/colocation-quote", formattedData);

        // Handle success response
        if (response.status === 200) {
            return { 
                success: true, 
                message: response.data.message || "Quote request submitted successfully!" 
            };
        }

        // Handle failure response
        console.error("Colocation quote submission failed:", response.data);
        return { 
            success: false, 
            message: response.data.error || "Quote submission failed." 
        };
    } catch (error) {
        // Handle error during the submission process
        console.error("Error Sending Colocation Quote Form:", error.response?.data || error.message);
        return { 
            success: false, 
            message: "An error occurred. Please try again." 
        };
    }
};
  