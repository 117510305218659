import React, { useEffect, useRef, useState } from 'react';
import styles from './FilterModal.module.scss';
import DownIcon from '../../../../Assets/icons/Down.svg';

const FilterModal = ({ 
  isOpen, 
  onClose, 
  filterOptions, 
  selectedFilters, 
  handleFilterToggle,
  priceRange,
  setPriceRange,
  minPrice,
  maxPrice
}) => {
  const [openFilters, setOpenFilters] = useState([]);
  const modalRef = useRef();

  // Get available models based on selected brands
  const getAvailableModels = () => {
    const selectedBrands = Array.from(selectedFilters.Brand);
    if (selectedBrands.length === 0) {
      // If no brand is selected, show all models
      return Object.values(filterOptions.Model).flat();
    }
    return selectedBrands.reduce((acc, brand) => {
      return [...acc, ...(filterOptions.Model[brand] || [])];
    }, []);
  };

  // Handle clicking outside modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Toggle filter sections
  const toggleOpen = (index) => {
    setOpenFilters((prevOpenFilters) => {
      const updatedFilters = [...prevOpenFilters];
      updatedFilters[index] = !updatedFilters[index];
      return updatedFilters;
    });
  };

  // Handle brand selection and update models accordingly
  const handleBrandSelection = (brand) => {
    handleFilterToggle('Brand', brand);
    
    // Clear model selection if the brand is deselected
    const newBrandSet = new Set(selectedFilters.Brand);
    if (newBrandSet.has(brand)) {
      newBrandSet.delete(brand);
    } else {
      newBrandSet.add(brand);
    }
    
    // Clear models that don't belong to selected brands
    const availableModels = newBrandSet.size === 0 
      ? Object.values(filterOptions.Model).flat()
      : Array.from(newBrandSet).reduce((acc, b) => [...acc, ...filterOptions.Model[b]], []);
    
    selectedFilters.Model.forEach(model => {
      if (!availableModels.includes(model)) {
        handleFilterToggle('Model', model);
      }
    });
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal} ref={modalRef}>
        <div className={styles.modalHeader}>
          <h2>Filters</h2>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>

        <div className={styles.modalContent}>
          {/* Price Range Slider */}
          <div className={styles.priceFilter}>
            <h3>Price Range</h3>
            <div className={styles.priceSliderContainer}>
              <div className={styles.priceLabels}>
                <span>${priceRange[0]}</span>
                <span>${priceRange[1]}</span>
              </div>
              <input
                type="range"
                min={minPrice}
                max={maxPrice}
                value={priceRange[0]}
                onChange={(e) => setPriceRange([parseInt(e.target.value), priceRange[1]])}
                className={styles.slider}
              />
              <input
                type="range"
                min={minPrice}
                max={maxPrice}
                value={priceRange[1]}
                onChange={(e) => setPriceRange([priceRange[0], parseInt(e.target.value)])}
                className={styles.slider}
              />
            </div>
          </div>

          {/* Other Filters */}
          <div className={styles.filterOptionsContainer}>
            {Object.entries(filterOptions).map(([filterType, options], index) => {
              // Skip Model options as they will be handled separately
              if (filterType === 'Model') return null;

              return (
                <div key={filterType} className={styles.filterItem}>
                  <div
                    className={`${styles.filterOptionsItemClosed} ${
                      openFilters[index] ? styles.active : ""
                    }`}
                    onClick={() => toggleOpen(index)}
                  >
                    <p>{filterType}</p>
                    <img
                      src={DownIcon}
                      alt={`Toggle ${filterType}`}
                      style={{
                        transform: openFilters[index]
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </div>

                  {openFilters[index] && (
                    <div className={`${styles.filterOptionsItemOpen} ${openFilters[index] ? styles.expanded : ""}`}>
                      <ul className={styles.dropdownList}>
                        {filterType === 'Brand' ? (
                          // Brand options with special handling
                          options.map((option) => (
                            <li key={option} className={styles.dropdownItem}>
                              <label className={styles.dropdownLabel}>
                                <input
                                  type="checkbox"
                                  className={styles.checkbox}
                                  checked={selectedFilters[filterType].has(option)}
                                  onChange={() => handleBrandSelection(option)}
                                />
                                {option}
                              </label>
                            </li>
                          ))
                        ) : (
                          // Regular options
                          options.map((option) => (
                            <li key={option} className={styles.dropdownItem}>
                              <label className={styles.dropdownLabel}>
                                <input
                                  type="checkbox"
                                  className={styles.checkbox}
                                  checked={selectedFilters[filterType].has(option)}
                                  onChange={() => handleFilterToggle(filterType, option)}
                                />
                                {option}
                              </label>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              );
            })}

            {/* Model Filter (dependent on Brand selection) */}
            <div className={styles.filterItem}>
              <div
                className={`${styles.filterOptionsItemClosed} ${
                  openFilters[Object.keys(filterOptions).length] ? styles.active : ""
                }`}
                onClick={() => toggleOpen(Object.keys(filterOptions).length)}
              >
                <p>Model</p>
                <img
                  src={DownIcon}
                  alt="Toggle Model"
                  style={{
                    transform: openFilters[Object.keys(filterOptions).length]
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              </div>

              {openFilters[Object.keys(filterOptions).length] && (
                <div className={`${styles.filterOptionsItemOpen} ${openFilters[Object.keys(filterOptions).length] ? styles.expanded : ""}`}>
                  <ul className={styles.dropdownList}>
                    {getAvailableModels().map((option) => (
                      <li key={option} className={styles.dropdownItem}>
                        <label className={styles.dropdownLabel}>
                          <input
                            type="checkbox"
                            className={styles.checkbox}
                            checked={selectedFilters.Model.has(option)}
                            onChange={() => handleFilterToggle('Model', option)}
                          />
                          {option}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.modalFooter}>
          <button className={styles.applyButton} onClick={onClose}>
            Apply Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal; 