// Import Libraries
import React from "react";
import { Helmet } from "react-helmet";

// Import SCSS
import styles from "./ServerUpgradesPage.module.scss";

// Import Components
import HeroComponent from "../../Layout/Components/HeroComponent/HeroComponent";
import ServerUpgradesComponent from "./Components/ServerUpgradesComponent/ServerUpgradesComponent";

const ServerUpgradesPage = () => {
  return (
    <div>
      <Helmet>
        <title>Server Upgrades | Enhance Your WebNX Dedicated Servers</title>
        <meta
          name="description"
          content="Explore upgrade options for WebNX dedicated servers. From memory and storage to GPUs and operating systems, customize your server for peak performance."
        />
        <meta
          name="keywords"
          content="server upgrades, WebNX upgrades, GPU upgrades, storage upgrades, memory upgrades, WebNX dedicated server upgrades"
        />
        <meta property="og:title" content="Server Upgrades | WebNX" />
        <meta
          property="og:description"
          content="Upgrade your WebNX dedicated servers with ease. Choose from memory, storage, GPUs, and more to optimize your server performance."
        />
        <meta property="og:image" content="https://webnx.com/assets/server-upgrades.jpg" />
        <meta property="og:url" content="https://webnx.com/server-upgrades" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webnx.com/server-upgrades" />
      </Helmet>

      {/* <HeroComponent
        title="Server Upgrades"
        subtitle="Enhance Your Dedicated Servers with WebNX Upgrades"
        showButton1={false}
      /> */}

      <ServerUpgradesComponent />
    </div>
  );
};

export default ServerUpgradesPage;
