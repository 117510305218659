// Import Libraries
import React, { useState } from "react";
import Lottie from "react-lottie";

// Import SCSS
import styles from "./ColocationQuoteForm.module.scss";

// Import Animations
import successAnimationData from ".././../../../Assets/video/Confetti.json";
import FadeInOnScroll from "../../../../util/Animations/FadeInOnScroll";

// Import Components
import CaptchaModalComponent from "../../../../Components/CaptchaModalComponent/CaptchaModalComponent";

// Import ApiLayer
import { sendColocationQuoteForm } from "../../../../ApiLayer/sendColocationOrderForm";

const ColocationQuoteForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    location: "",
    cabinetSize: "",
    powerRequirements: "",
    networkSpeed: "",
    ipAddresses: "",
    crossConnects: "",
    remoteHands: "",
    additionalNotes: "",
    networkRedundancy: "",
    rackSpace: "",
    cabinetType: "",
    access: "",
    backupPower: "",
    monitoring: "",
    dataBackup: "",
    ddosProtection: "",
  });

  const [errors, setErrors] = useState({});
  const [statusMessage, setStatusMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };

  const locations = ["Ogden, UT", "Los Angeles, CA"];
  const cabinetSizes = ["1U/2U Server", "4U Server", "Half Cabinet", "Full Cabinet"];
  const powerOptions = ["120V 20A", "208V 30A", "208V 50A", "Custom Power"];
  const networkSpeeds = [
    "1 Gbps",
    "10 Gbps",
    "10Gbps Port / 50TB Outbound",
    "10Gbps Port / 100TB Outbound",
  ];
  const ipOptions = ["1", "2", "5", "10", "20", "50", "100", "/29 IPv4"];
  const crossConnectOptions = ["None", "1", "2", "3", "4", "5+"];
  const remoteHandsOptions = ["Basic", "Advanced", "Premium", "Custom"];
  const networkRedundancyOptions = ["None", "N+1", "2N", "2N+1"];
  const cabinetTypeOptions = ["Standard", "Locking", "Custom"];
  const accessOptions = ["24/7", "Business Hours", "Scheduled"];
  const backupPowerOptions = ["None", "N+1", "2N", "2N+1"];
  const monitoringOptions = ["Basic", "Advanced", "Premium", "Custom"];
  const dataBackupOptions = ["None", "Daily", "Weekly", "Custom"];
  const ddosProtectionOptions = ["None", "Basic", "Advanced", "Premium"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      isValid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleCaptchaVerification = async (token) => {
    setShowCaptchaModal(false);

    try {
      const response = await sendColocationQuoteForm(formData, token);
      if (response.success) {
        setStatusMessage(response.message);
        setIsError(false);
        setShowSuccessAnimation(true);
      } else {
        setIsError(true);
        setStatusMessage(response.message);
      }
    } catch (error) {
      setIsError(true);
      setStatusMessage("An error occurred. Please try again.");
    }

    setFormData({
      name: "",
      email: "",
      phone: "",
      companyName: "",
      location: "",
      cabinetSize: "",
      powerRequirements: "",
      networkSpeed: "",
      ipAddresses: "",
      crossConnects: "",
      remoteHands: "",
      additionalNotes: "",
      networkRedundancy: "",
      rackSpace: "",
      cabinetType: "",
      access: "",
      backupPower: "",
      monitoring: "",
      dataBackup: "",
      ddosProtection: "",
    });
    setErrors({});
    setTimeout(() => setShowSuccessAnimation(false), 5000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setIsError(true);
      setStatusMessage("Please fill out all required fields correctly.");
      return;
    }
    setShowCaptchaModal(true);
  };

  return (
    <div className={styles.background}>
      <FadeInOnScroll>
        <div className={styles.container}>
          <div className={styles.formContainer}>
            <h1 className={styles.header}>Request a Colocation Quote</h1>
            <p className={styles.text}>
              Configure your colocation requirements and send a quote request. Our team
              will review your specifications and get back to you.
            </p>

            {statusMessage && (
              <p className={isError ? styles.errorText : styles.successText}>
                {statusMessage}
              </p>
            )}
            {showSuccessAnimation && (
              <div className={styles.lottieTopRight}>
                <Lottie
                  options={defaultOptions}
                  height={700}
                  width={700}
                  key={Date.now()}
                />
              </div>
            )}

            <form className={styles.quoteForm} onSubmit={handleSubmit}>
              {/* Contact Information Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Contact Information</h3>
                <div className={styles.grid}>
                  {[
                    {
                      label: "Name",
                      name: "name",
                      type: "text",
                      placeholder: "Your Name",
                      error: errors.name,
                    },
                    {
                      label: "Email",
                      name: "email",
                      type: "email",
                      placeholder: "Your Email",
                      error: errors.email,
                    },
                    {
                      label: "Phone",
                      name: "phone",
                      type: "tel",
                      placeholder: "Your Phone",
                    },
                    {
                      label: "Company Name",
                      name: "companyName",
                      type: "text",
                      placeholder: "Your Company Name",
                    },
                  ].map((field) => (
                    <div key={field.name} className={styles.formGroup}>
                      <label htmlFor={field.name}>{field.label}</label>
                      <input
                        type={field.type}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        placeholder={field.placeholder}
                      />
                      {field.error && (
                        <span className={styles.errorText}>{field.error}</span>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* Location and Cabinet Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Location & Cabinet</h3>
                <div className={styles.grid}>
                  <div className={styles.formGroup}>
                    <label htmlFor="location">Location</label>
                    <select
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select Location</option>
                      {locations.map((location) => (
                        <option key={location} value={location}>
                          {location}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="cabinetSize">Cabinet Size</label>
                    <select
                      name="cabinetSize"
                      value={formData.cabinetSize}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select Cabinet Size</option>
                      {cabinetSizes.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="powerRequirements">Power Requirements</label>
                    <select
                      name="powerRequirements"
                      value={formData.powerRequirements}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select Power Requirements</option>
                      {powerOptions.map((power) => (
                        <option key={power} value={power}>
                          {power}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* Network Configuration Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Network Configuration</h3>
                <div className={styles.grid}>
                  <div className={styles.formGroup}>
                    <label htmlFor="networkSpeed">Network Speed</label>
                    <select
                      name="networkSpeed"
                      value={formData.networkSpeed}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select Network Speed</option>
                      {networkSpeeds.map((speed) => (
                        <option key={speed} value={speed}>
                          {speed}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="ipAddresses">IP Addresses</label>
                    <select
                      name="ipAddresses"
                      value={formData.ipAddresses}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select IP Count</option>
                      {ipOptions.map((count) => (
                        <option key={count} value={count}>
                          {count}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="crossConnects">Cross Connects</label>
                    <select
                      name="crossConnects"
                      value={formData.crossConnects}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select Cross Connects</option>
                      {crossConnectOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="networkRedundancy">Network Redundancy</label>
                    <select
                      name="networkRedundancy"
                      value={formData.networkRedundancy}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select Network Redundancy</option>
                      {networkRedundancyOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="ddosProtection">DDoS Protection</label>
                    <select
                      name="ddosProtection"
                      value={formData.ddosProtection}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select DDoS Protection Level</option>
                      {ddosProtectionOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* Space Requirements Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Space Requirements</h3>
                <div className={styles.grid}>
                  <div className={styles.formGroup}>
                    <label htmlFor="rackSpace">Rack Space</label>
                    <input
                      type="text"
                      name="rackSpace"
                      value={formData.rackSpace}
                      onChange={handleChange}
                      placeholder="Enter required rack space (e.g., 42U)"
                      className={styles.textInput}
                    />
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="cabinetType">Cabinet Type</label>
                    <select
                      name="cabinetType"
                      value={formData.cabinetType}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select Cabinet Type</option>
                      {cabinetTypeOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="access">Access Requirements</label>
                    <select
                      name="access"
                      value={formData.access}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select Access Requirements</option>
                      {accessOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* Additional Services Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Additional Services</h3>
                <div className={styles.grid}>
                  <div className={styles.formGroup}>
                    <label htmlFor="remoteHands">Remote Hands Service Level</label>
                    <select
                      name="remoteHands"
                      value={formData.remoteHands}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select Service Level</option>
                      {remoteHandsOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="backupPower">Backup Power</label>
                    <select
                      name="backupPower"
                      value={formData.backupPower}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select Backup Power Level</option>
                      {backupPowerOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="monitoring">Monitoring</label>
                    <select
                      name="monitoring"
                      value={formData.monitoring}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select Monitoring Level</option>
                      {monitoringOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="dataBackup">Data Backup</label>
                    <select
                      name="dataBackup"
                      value={formData.dataBackup}
                      onChange={handleChange}
                      className={styles.selectInput}
                    >
                      <option value="">Select Data Backup Level</option>
                      {dataBackupOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* Additional Notes Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Additional Notes</h3>
                <div className={styles.formGroup}>
                  <label htmlFor="additionalNotes">Additional Notes</label>
                  <textarea
                    name="additionalNotes"
                    value={formData.additionalNotes}
                    onChange={handleChange}
                    placeholder="Enter any additional requirements or specifications here"
                    rows="4"
                    className={styles.textAreaInput}
                  />
                </div>
              </div>

              {/* Submit Button */}
              <button type="submit" className={styles.sendButton}>
                Submit Quote Request
              </button>
            </form>
          </div>
        </div>
      </FadeInOnScroll>
      <CaptchaModalComponent
        isOpen={showCaptchaModal}
        onClose={() => setShowCaptchaModal(false)}
        onVerify={handleCaptchaVerification}
      />
    </div>
  );
};

export default ColocationQuoteForm;
