import React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import styles from "./ServerPageIntro.module.scss";
import { 
  FaServer, 
  FaNetworkWired, 
  FaShieldAlt, 
  FaCog, 
  FaRocket, 
  FaUserCog,
  FaMicrochip,
  FaBrain,
  FaBolt,
  FaChartLine,
  FaHdd,
  FaDatabase,
  FaExchangeAlt,
  FaLock,
  FaClock
} from 'react-icons/fa';

const ServerPageIntro = ({
  header,
  subheader,
  introText,
  features,
  image,
  showButton,
  buttonText,
  buttonLink,
  hideImageOnMobile,
}) => {
  // Sanitize the incoming HTML
  const cleanHeader = DOMPurify.sanitize(header);
  const cleanSubheader = DOMPurify.sanitize(subheader);
  const cleanIntroText = DOMPurify.sanitize(introText);

  // Icon mapping
  const iconMap = {
    FaServer: <FaServer className={styles.featureIcon} />,
    FaNetworkWired: <FaNetworkWired className={styles.featureIcon} />,
    FaShieldAlt: <FaShieldAlt className={styles.featureIcon} />,
    FaCog: <FaCog className={styles.featureIcon} />,
    FaRocket: <FaRocket className={styles.featureIcon} />,
    FaUserCog: <FaUserCog className={styles.featureIcon} />,
    FaMicrochip: <FaMicrochip className={styles.featureIcon} />,
    FaBrain: <FaBrain className={styles.featureIcon} />,
    FaBolt: <FaBolt className={styles.featureIcon} />,
    FaChartLine: <FaChartLine className={styles.featureIcon} />,
    FaHdd: <FaHdd className={styles.featureIcon} />,
    FaDatabase: <FaDatabase className={styles.featureIcon} />,
    FaExchangeAlt: <FaExchangeAlt className={styles.featureIcon} />,
    FaLock: <FaLock className={styles.featureIcon} />,
    FaClock: <FaClock className={styles.featureIcon} />
  };

  return (
    <section className={styles.container} id="moreInfo">
      <div className={styles.dedicatedServersContainer}>
        <div className={styles.contentWrapper}>
          {/* Left Section - Header and Text */}
          <div className={styles.dedicatedServersLeft}>
            <div className={styles.dedicatedServersHeader}>
              <div className={styles.headerTextContainer}>
                <div className={styles.headerText} dangerouslySetInnerHTML={{ __html: cleanHeader }} />
                <div className={`${styles.imageContainer} ${hideImageOnMobile ? styles.hideOnMobile : ""}`}>
                  <img
                    className={styles.standardServer}
                    src={image}
                    alt={header.replace(/<br\s*\/?>/gi, " ")}
                  />
                </div>
              </div>
            </div>
            <h3
              className={styles.dedicatedServersSubHeader}
              dangerouslySetInnerHTML={{ __html: cleanSubheader }}
            />
            <p
              className={styles.dedicatedServersText}
              dangerouslySetInnerHTML={{ __html: cleanIntroText }}
            />
            {showButton && (
              <a
                href={buttonLink}
                rel="noopener noreferrer"
                className={styles.button}
                aria-label={buttonText}
              >
                {buttonText}
              </a>
            )}
          </div>

          {/* Right Section - Features Grid */}
          <div className={styles.featuresSection}>
            {features && features.length > 0 && (
              <div className={styles.featuresGrid}>
                {features.map((feature, index) => (
                  <div key={index} className={styles.featureItem}>
                    {iconMap[feature.icon]}
                    <div className={styles.featureContent}>
                      <h4>{feature.title}</h4>
                      <p>{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ServerPageIntro.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  introText: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string
    })
  ),
  image: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  hideImageOnMobile: PropTypes.bool,
};

ServerPageIntro.defaultProps = {
  header: "WebNX Dedicated Servers",
  subheader: "Dedicated Servers",
  introText: "",
  features: [],
  showButton: false,
  buttonText: "Learn More",
  buttonLink: "#",
  hideImageOnMobile: false,
};

export default ServerPageIntro;
