// Import Libraries
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";

// Import CSS
import "./App.css";
import "./scss/global.scss";

// Import Components
import Layout from "./Layout/Layout";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import HomePage from "./Pages/HomePage/HomePage";
import AllDedicatedServersPage from "./Pages/AllDedicatedServersPage/AllDedicatedServersPage";
import StandardDedicatedServersPage from "./Pages/DedicatedServersPage/DedicatedServersPage";
import WhoIsWebNXPage from "./Pages/WhoIsWebNXPage/WhoIsWebNXPage";
import DataCenterAndLocationsPage from "./Pages/DataCenterLocationsAndNetworksPage/DataCenterAndLocationsPage";
import ErrorBoundary from "./util/ErrorBoundary/ErrorBoundary";
import CustomDedicatedServersPage from "./Pages/CustomDedicatedServersPage/CustomDedicatedServersPage";
import GpuEnabledServersPage from "./Pages/GpuEnabledServersPage/GpuEnabledServersPage";
import StorageServerPage from "./Pages/StorageServerPage/StorageServerPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import TermsOfServicePage from "./Pages/TermsOfService/TermsOfServicePage";
import ServiceLevelAgreementPage from "./Pages/ServiceLevelAgreementPage/ServiceLevelAgreementPage";
import ServerUpgradesPage from "./Pages/ServerUpgradesPage/ServerUpgradesPage";
import ColocationPage from "./Pages/ColocationPage/ColocationPage";
import TrackPageView from "./util/GoogleAnalytics/TrackPageView";
import NewYorkDataCenterAndLocations from "./Pages/DataCenterLocationsAndNetworksPage/SubPages/NewYorkDataCenterAndLocations";
import CaliforniaDataCenterAndLocationsPage from "./Pages/DataCenterLocationsAndNetworksPage/SubPages/CaliforniaDataCenterAndLocationsPage";
import UtahDataCenterAndLocationsPage from "./Pages/DataCenterLocationsAndNetworksPage/SubPages/UtahDataCenterAndLocationsPage";

// SEO Configuration
const defaultSEO = {
  title: "WebNX | Enterprise Internet Infrastructure Solutions",
  description: "WebNX offers reliable and high-performance dedicated servers, GPU-enabled servers, colocation services, and enterprise infrastructure solutions tailored to your business needs.",
  keywords: "dedicated servers, GPU servers, colocation services, enterprise hosting, data centers, server infrastructure, WebNX",
  author: "WebNX",
  image: "https://webnx.com/images/webnx-og-image.jpg",
  url: "https://webnx.com"
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: (
      <Layout>
        <ErrorPage />
      </Layout>
    ),
    children: [
      { index: true, element: <HomePage /> },
      { path: "all-dedicated-servers", element: <AllDedicatedServersPage /> },
      { path: "standard-dedicated-servers", element: <StandardDedicatedServersPage /> },
      { path: "custom-dedicated-servers", element: <CustomDedicatedServersPage /> },
      { path: "gpu-enabled-servers", element: <GpuEnabledServersPage /> },
      { path: "storage-servers", element: <StorageServerPage /> },
      { path: "server-upgrades", element: <ServerUpgradesPage /> },
      { path: "about", element: <WhoIsWebNXPage /> },
      // { path: "datacenter-locations-and-networks", element: <DataCenterAndLocationsPage /> },
      { path: "datacenter-locations-and-networks/utah", element: <UtahDataCenterAndLocationsPage /> },
      { path: "datacenter-locations-and-networks/california", element: <CaliforniaDataCenterAndLocationsPage /> },
      { path: "datacenter-locations-and-networks/newyork", element: <NewYorkDataCenterAndLocations /> },
      { path: "contact", element: <ContactPage /> },
      { path: "terms-of-service", element: <TermsOfServicePage /> },
      { path: "service-level-agreement", element: <ServiceLevelAgreementPage /> },
      { path: "colocation", element: <ColocationPage /> },
      { path: "error", element: <ErrorPage /> },
    ],
  },
]);

const App = () => {
  useEffect(() => {
    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

    if (measurementId) {
      ReactGA.initialize(measurementId);
      console.log("Google Analytics initialized");
    } else {
      console.log("Google Analytics not initialized: no Measurement ID provided");
    }
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>{defaultSEO.title}</title>
        <meta name="description" content={defaultSEO.description} />
        <meta name="keywords" content={defaultSEO.keywords} />
        <meta name="author" content={defaultSEO.author} />

        {/* OpenGraph Meta Tags */}
        <meta property="og:title" content={defaultSEO.title} />
        <meta property="og:description" content={defaultSEO.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={defaultSEO.url} />
        <meta property="og:image" content={defaultSEO.image} />
        <meta property="og:site_name" content="WebNX" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={defaultSEO.title} />
        <meta name="twitter:description" content={defaultSEO.description} />
        <meta name="twitter:image" content={defaultSEO.image} />

        {/* Additional SEO Meta Tags */}
        <meta name="robots" content="index, follow, max-image-preview:large" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="7 days" />
        <meta name="distribution" content="global" />
        <meta name="rating" content="general" />

        {/* Canonical URL */}
        <link rel="canonical" href={defaultSEO.url} />

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/logo192.png" />
      </Helmet>
      <RouterProvider router={router}>
        <TrackPageView />
      </RouterProvider>
    </HelmetProvider>
  );
};

export default App;
