// Import Libraries
import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './ContactPage.module.scss';

// Import SCSS
import HeroComponent from '../../Layout/Components/HeroComponent/HeroComponent';
import ContactComponent from './Components/ContactComponent/ContactComponent';

// Import Animation
import FadeInOnScroll from '../../util/Animations/FadeInOnScroll';

const ContactPage = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "WebNX",
    "url": "https://webnx.com",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+1-800-840-5996",
        "contactType": "Customer Service",
        "areaServed": "US",
        "availableLanguage": "English"
      },
      {
        "@type": "ContactPoint",
        "email": "sales@webnx.com",
        "contactType": "Sales",
        "areaServed": "US",
        "availableLanguage": "English"
      }
    ],
    "sameAs": [
      "https://www.facebook.com/webnx",
      "https://twitter.com/webnx",
      "https://www.linkedin.com/company/webnx"
    ]
  };

  return (
    <div>
      {/* Helmet for Meta Tags and SEO */}
      <Helmet>
        <title>Contact Us | WebNX</title>
        <meta
          name="description"
          content="Contact WebNX for exceptional hosting solutions, dedicated server support, and customer assistance. Reach us via phone, email, or support ticket."
        />
        <meta
          name="keywords"
          content="WebNX contact, hosting support, dedicated servers, customer service"
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      {/* Hero Section */}
      {/* <HeroComponent
        title="Contact"
        subtitle="Contact Us"
        buttonText1="Shop Servers Now"
        buttonLink1="#filterComponent"
        showButton1={false}
        buttonText2="More Information"
        buttonLink2="#moreInformation"
        showButton2={false}
      /> */}

      {/* Contact Section */}
      <main className={styles.main}>
        <FadeInOnScroll>
          <section>
            <ContactComponent />
          </section>
        </FadeInOnScroll>
      </main>
    </div>
  );
};

export default ContactPage;
