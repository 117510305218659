// Import Libraries
import React from "react";
import { Helmet } from "react-helmet";

// Import SCSS
import styles from "./StorageServerPage.module.scss";

// Import Components
import ServerPageIntro from "../../Components/ServerPageIntro/ServerPageIntro";
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import ServerStandardsComponent from "../../Components/ServerStandardsComponent/ServerStandardsComponent";
import DedicatedServerLocations from "../DedicatedServersPage/Components/DedicatedServerLocations/DedicatedServerLocations";
import TestNetworkSpeedsComponent from "../DedicatedServersPage/Components/TestNetworkSpeedsComponent/TestNetworkSpeedsComponent";

// Import Assets
import StorageServer from "../../Assets/Storage_Server.png";

// Import Animation
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

const StorageServerPage = () => {
  return (
    <div>
      {/* Helmet for Metadata */}
      <Helmet>
        <title>Storage Servers | Scalable Data Solutions by WebNX</title>
        <meta
          name="description"
          content="Explore WebNX storage servers, designed for high-volume data, backups, media, and databases. Reliable, scalable solutions for all storage needs."
        />
        <meta
          name="keywords"
          content="storage servers, scalable storage solutions, NAS servers, SAN servers, data storage servers, WebNX storage"
        />
        <meta property="og:title" content="Storage Servers | Scalable Data Solutions by WebNX" />
        <meta
          property="og:description"
          content="Reliable and scalable storage server solutions by WebNX. Optimize data management with trusted hardware and high-performance networks."
        />
        <meta property="og:image" content="https://webnx.com/assets/storage-server.jpg" />
        <meta property="og:url" content="https://webnx.com/storage-servers" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webnx.com/storage-servers" />
      </Helmet>

      <FadeInOnScroll>
        <ServerPageIntro
          header="WebNX Storage Servers"
          subheader="Enterprise-Grade Storage Solutions"
          introText="Meet your growing data storage needs with WebNX's custom-built storage servers. Designed for high-volume data, backups, media files, and databases, our solutions deliver the perfect combination of capacity and performance. With trusted hardware from industry leaders and our optimized network infrastructure, your data remains accessible, secure, and performing at its best."
          image={StorageServer}
          hideImageOnMobile={true}
          features={[
            {
              icon: "FaHdd",
              title: "Massive Storage",
              description: "Up to 100TB+ raw storage with RAID configuration options"
            },
            {
              icon: "FaDatabase",
              title: "Data Protection",
              description: "Hardware RAID controllers and hot-swappable drive bays"
            },
            {
              icon: "FaExchangeAlt",
              title: "High Throughput",
              description: "10Gbps networking with jumbo frame support for optimal data transfer"
            },
            {
              icon: "FaLock",
              title: "Data Security",
              description: "Encryption at rest and in transit with secure remote management"
            }
          ]}
        />
      </FadeInOnScroll>

      {/* Filter Component */}
      <FilterComponent defaultFilters={{ "Server Type": ["Storage"] }} />

      <FadeInOnScroll>
        <ServerStandardsComponent />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <DedicatedServerLocations />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <TestNetworkSpeedsComponent />
      </FadeInOnScroll>
    </div>
  );
};

export default StorageServerPage;
