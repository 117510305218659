// HomePage.js
import React from "react";
import { Helmet } from "react-helmet";

// Import other components
import HeroComponent from "../../Layout/Components/HeroComponent/HeroComponent";
import OurServicesComponent from "./Components/OurServicesComponent.js/OurServicesComponent";
import ServerStandardsComponent from "../../Components/ServerStandardsComponent/ServerStandardsComponent";
import FeaturedSolutionsComponent from "./Components/FeaturedSolutionsComponent.js/FeaturedSolutionsComponent";
import ReviewsComponent from "../WhoIsWebNXPage/Components/ReviewsComponent/ReviewsComponent";

// Import Assets
import Wifi from "../../Assets/icons/Wifi-Icon-Plain.svg";
import BellIcon from "../../Assets/icons/Bell-Icon-Plain.svg";
import ShieldIcon from "../../Assets/icons/Shield-Icon-Plain.svg";
import BandwidthIcon from "../../Assets/icons/Bandwidth-Icon-Plain.svg";
import NetworkIcon from "../../Assets/icons/Hardware-Icon-Plain.svg";
import PowerIcon from "../../Assets/icons/SLA-Icon-Plain.svg";

// Import Animation HOC
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

const HomePage = () => {
  return (
    <div>
      <Helmet>
        <title>
          High-Performance Dedicated Servers | GPU, Storage & Custom Solutions
          by WebNX
        </title>
        <meta
          name="description"
          content="WebNX provides enterprise-grade internet infrastructure with high-performance dedicated servers, GPU-enabled servers, scalable storage solutions, and customizable hardware for diverse business needs."
        />
        <meta
          name="keywords"
          content="enterprise internet infrastructure, high-performance dedicated servers, GPU-enabled servers, storage solutions, custom server hosting, WebNX data centers"
        />
        <meta
          property="og:title"
          content="WebNX | High-Performance Dedicated Servers and Hosting Solutions"
        />
        <meta
          property="og:description"
          content="Discover WebNX's enterprise solutions: dedicated servers, GPU-enabled servers, scalable storage, and custom configurations for optimized performance."
        />
        <meta property="og:url" content="https://webnx.com" />
        <meta
          property="og:image"
          content="https://webnx.com/assets/hero-image.jpg"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webnx.com" />
      </Helmet>

      <HeroComponent
        title="Welcome to WebNX"
        subtitle="Enterprise Internet <br /> Infrastructure at Affordable <br />  Prices"
        showButton1={true}
        buttonText1="Shop Servers"
        buttonLink1="/standard-dedicated-servers#filterComponent"
      />

      <FadeInOnScroll>
        <FeaturedSolutionsComponent />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <OurServicesComponent />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <ServerStandardsComponent
          title="Why Choose WebNX?"
          subtitle="The WebNX Advantage"
          description="At WebNX, we pride ourselves on offering Enterprise Internet Infrastructure Solutions at affordable prices. We combine top-quality components from the top manufacturers with our 100% self-operated data centers and our Route Optimized Networks to bring you IT values unlike anyone else. With over 15 years of industry experience, your enterprise is in good hands with WebNX Internet Services."
          standards={[
            {
              icon: Wifi,
              title: "100% Network SLA",
              description: "Guaranteed uptime for network connectivity, ensuring no interruptions in service."
            },
            {
              icon: PowerIcon,
              title: "100% Power SLA",
              description: "Uninterrupted power supply backed by redundant systems to maintain continuous operations."
            },
            {
              icon: BellIcon,
              title: "24x7x365 Technical Support",
              description: "Always available expert support to assist with any technical issues, at any time of day or year."
            },
            {
              icon: BandwidthIcon,
              title: "Tier-1 Bandwidth Providers",
              description: "High-performance, reliable internet connectivity through partnerships with top-tier bandwidth providers."
            },
            {
              icon: ShieldIcon,
              title: "Self-Operated Data Centers",
              description: "Fully owned and managed data centers for complete control over infrastructure and services."
            },
            {
              icon: NetworkIcon,
              title: "Flexible Hardware Configurations",
              description: "Customizable server and hardware setups to meet specific business needs and scalability requirements."
            }
          ]}
        />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <ReviewsComponent />
      </FadeInOnScroll>
    </div>
  );
};

export default HomePage;
