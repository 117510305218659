import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization
import { motion, useScroll, useTransform } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import styles from '../HeroComponent/HeroComponent.module.scss';

const HeroComponent = ({
  title,
  subtitle,
  buttonText1,
  buttonLink1,
  buttonText2,
  buttonLink2,
  showButton1,
  showButton2,
  customStyles,
}) => {
  const { scrollY } = useScroll();
  const navigate = useNavigate();

  // Enhanced animations
  const textScale = useTransform(scrollY, [0, 400], [1, 0.9]);
  const textOpacity = useTransform(scrollY, [0, 400], [1, 0]);
  const overlayOpacity = useTransform(scrollY, [0, 400], [0.3, 0.95]);
  const buttonOpacity = useTransform(scrollY, [0, 200], [1, 0]);
  const buttonScale = useTransform(scrollY, [0, 200], [1, 0.8]);

  const cleanSubtitle = subtitle ? DOMPurify.sanitize(subtitle) : '';

  const handleScroll = (e, target) => {
    e.preventDefault();
    // Extract the path and hash from the target URL
    const [path, hash] = target.split('#');
    
    // Navigate to the new page
    navigate(path);
    
    // Wait for the navigation to complete before scrolling
    setTimeout(() => {
      const element = document.querySelector(`#${hash}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <header className={`${styles.backgroundImage}`} style={customStyles}>
      {/* Dark overlay with opacity effect */}
      <motion.div
        className={styles.overlay}
        style={{ opacity: overlayOpacity }}
      />

      {/* Text with scaling and fading effect */}
      <motion.div
        className={styles.overlayText}
        style={{ scale: textScale, opacity: textOpacity }}
      >
        <div>
          {title && <h1>{title}</h1>}
          {subtitle && (
            <h2
              dangerouslySetInnerHTML={{ __html: cleanSubtitle }}
              className={styles.subtitle}
            />
          )}
        </div>
        <motion.div 
          className={styles.buttonContainer}
          style={{ scale: buttonScale, opacity: buttonOpacity }}
        >
          {showButton1 && (
            <a
              href={buttonLink1}
              className={styles.heroButton}
              onClick={(e) => handleScroll(e, buttonLink1)}
              aria-label={buttonText1}
            >
              {buttonText1}
            </a>
          )}
          {showButton2 && (
            <a
              href={buttonLink2}
              className={styles.heroButton2}
              onClick={(e) => handleScroll(e, buttonLink2)}
              aria-label={buttonText2}
            >
              {buttonText2}
            </a>
          )}
        </motion.div>
      </motion.div>

      <motion.div 
        className={styles.scrollIndicator}
        style={{ opacity: buttonOpacity }}
      >
        <div className={styles.mouse}>
          <div className={styles.wheel}></div>
        </div>
        <div className={styles.scrollText}>Scroll to explore</div>
      </motion.div>
    </header>
  );
};

// PropTypes to enforce prop types and default props
HeroComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText1: PropTypes.string,
  buttonLink1: PropTypes.string,
  buttonText2: PropTypes.string,
  buttonLink2: PropTypes.string,
  showButton1: PropTypes.bool,
  showButton2: PropTypes.bool,
  customStyles: PropTypes.object,
};

export default HeroComponent;