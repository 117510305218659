import React from 'react';
import styles from './Pagination.module.scss';

const Pagination = ({ 
    totalPages, 
    currentPage, 
    onPageChange,
    recordsPerPage,
    onRecordsPerPageChange 
}) => {
    if (totalPages <= 1) return null;

    const maxPagesToShow = 5;
    const pageNumbers = [];

    // Always show the first page
    pageNumbers.push(1);

    if (currentPage > maxPagesToShow - 2) {
        pageNumbers.push("...");
    }

    // Show middle pages around the current page
    let startPage = Math.max(2, currentPage - 1);
    let endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
        if (i > 1 && i < totalPages) {
            pageNumbers.push(i);
        }
    }

    if (currentPage < totalPages - (maxPagesToShow - 3)) {
        pageNumbers.push("...");
    }

    // Always show the last page if there is more than one page
    if (totalPages > 1) {
        pageNumbers.push(totalPages);
    }

    return (
        <div className={styles.paginationContainer}>
            <div className={styles.recordsPerPage}>
                <label>Show:</label>
                <select 
                    value={recordsPerPage} 
                    onChange={(e) => onRecordsPerPageChange(Number(e.target.value))}
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                </select>
                <span>per page</span>
            </div>

            <div className={styles.pagination}>
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    aria-label="Previous page"
                >
                    ←
                </button>

                {pageNumbers.map((page, index) => (
                    <button
                        key={index}
                        onClick={() => typeof page === "number" && onPageChange(page)}
                        className={currentPage === page ? styles.active : ""}
                        disabled={page === "..."}
                        aria-current={currentPage === page ? "page" : undefined}
                    >
                        {page}
                    </button>
                ))}

                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    aria-label="Next page"
                >
                    →
                </button>
            </div>
        </div>
    );
};

export default Pagination;