import React, { useState } from "react";
import styles from "./ColocationPricing.module.scss";
import ColocationQuoteForm from "../ColocationQuoteForm/ColocationQuoteForm";

const ColocationPricing = () => {
  const [activeLocation, setActiveLocation] = useState("ogden");
  const [showForm, setShowForm] = useState(false);

  const ogdenPlans = [
    {
      title: "1U/2U Server",
      price: "$45/mo",
      features: [
        "1 Amp 208V Power",
        "1Gbps Unmetered",
        "10G Available",
        "/29 (5 Usable IPs)",
        "IPv6 Available",
        "Remote Hands Support"
      ],
      link: "https://clients.webnx.com/index.php?rp=/store/colocation-ogden-utah/colo-ogd-1u-colocation",
    },
    {
      title: "4U Server",
      price: "$79/mo",
      features: [
        "2 Amp Power",
        "1Gbps Unmetered",
        "10G Available",
        "/29 (5 Usable IPs)",
        "IPv6 Available",
        "Remote Hands Support"
      ],
      link: "https://clients.webnx.com/index.php?rp=/store/colocation-ogden-utah/colo-ogd-4u-colocation",
    },
    {
      title: "Half Cabinet",
      price: "$499/mo",
      features: [
        "21U Space",
        "10 Amp Power",
        "1Gbps Unmetered",
        "10G Available",
        "/29 (5 Usable IPs)",
        "Remote Hands Support"
      ],
      link: "https://clients.webnx.com/index.php?rp=/store/colocation-ogden-utah/colo-ogd-12-rack-colocation",
    },
    {
      title: "Full Cabinet",
      price: "$899/mo",
      features: [
        "42U Space",
        "30A A+B Power",
        "10G Unmetered",
        "/28 (13 Usable IPs)",
        "IPv6 Available",
        "Remote Hands Support"
      ],
      link: "https://clients.webnx.com/index.php?rp=/store/colocation-ogden-utah/colo-ogd-full-rack-colocation",
    }
  ];

  const losAngelesPlans = [
    {
      title: "1U/2U Server",
      price: "$119/mo",
      features: [
        "1 Amp 208V Power",
        "10G port 50TB BW",
        "/29 (5 Usable IPs)",
        "IPv6 Available",
        "Remote Hands Support",
        "Premium LA Location"
      ],
      link: "https://clients.webnx.com/index.php?rp=/store/colocation-los-angeles/colo-la-1u-colocation",
    },
    {
      title: "4U Server",
      price: "$199/mo",
      features: [
        "2 Amp Power",
        "10G port 50TB BW",
        "/29 (5 Usable IPs)",
        "IPv6 Available",
        "Remote Hands Support",
        "Premium LA Location"
      ],
      link: "https://clients.webnx.com/index.php?rp=/store/colocation-los-angeles/colo-la-4u-colocation",
    },
    {
      title: "Half Cabinet",
      price: "$899/mo",
      features: [
        "21U Space",
        "5 Amp 208V Power",
        "1Gbps Unmetered",
        "10G Available",
        "/29 (5 Usable IPs)",
        "Remote Hands Support"
      ],
      link: "https://clients.webnx.com/index.php?rp=/store/colocation-los-angeles/colo-la-12-rack-colocation",
    },
    {
      title: "Full Cabinet",
      price: "$1500/mo",
      features: [
        "42U Space",
        "30A A+B Power",
        "10G Unmetered",
        "/28 (13 Usable IPs)",
        "IPv6 Available",
        "Remote Hands Support"
      ],
      link: "https://clients.webnx.com/index.php?rp=/store/colocation-los-angeles/colo-la-full-rack-colocation",
    }
  ];

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <h2 className={styles.header}>Colocation Plans</h2>
        <div className={styles.selectorContainer}>
          <div
            className={`${styles.ogden} ${
              activeLocation === "ogden" ? styles.active : ""
            }`}
            onClick={() => setActiveLocation("ogden")}
          >
            Ogden, UT
          </div>
          <div
            className={`${styles.losangeles} ${
              activeLocation === "losangeles" ? styles.active : ""
            }`}
            onClick={() => setActiveLocation("losangeles")}
          >
            Los Angeles, CA
          </div>
        </div>

        <div className={styles.cardGrid}>
          {(activeLocation === "ogden" ? ogdenPlans : losAngelesPlans).map(
            (plan, index) => (
              <div key={index} className={styles.solutionsCardContainer}>
                <div className={styles.solutionsCard}>
                  <div className={styles.cardContent}>
                    <div className={styles.solutionsCardTopRow}>
                      <div className={styles.solutionsCardTitle}>{plan.title}</div>
                      <div className={styles.solutionsCardInstantDeploy}>
                        Instant Deploy
                      </div>
                    </div>
                    <div className={styles.solutionsCardPrice}>{plan.price}</div>
                    <hr />
                    <div className={styles.solutionsCardBottomRow}>
                      <div className={styles.solutionsCardIncludes}>Includes</div>
                      <ul className={styles.solutionsList}>
                        {plan.features.map((feature, featureIndex) => (
                          <li key={featureIndex}>{feature}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className={styles.buttonContainer}>
                    <a
                      href={plan.link}
                      className={styles.orderNow}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Order Now
                    </a>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <div className={styles.customQuoteContainer}>
          <div className={styles.quoteContent}>
            <h3 className={styles.customHeader}>Need a Custom Solution?</h3>
            <p className={styles.customColocationText}>
              We understand that every business has unique requirements. Contact us for a custom colocation solution tailored to your specific needs.
            </p>
            <button
              onClick={handleShowForm}
              className={styles.getCustomOrderButton}
            >
              Get Custom Quote
            </button>
          </div>
          {showForm && <div className={styles.formWrapper}>
            <ColocationQuoteForm />
          </div>}
        </div>
      </div>
    </div>
  );
};

export default ColocationPricing;
