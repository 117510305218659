import React from "react";
import { motion } from "framer-motion";
import styles from "../FeaturedSolutionsComponent.module.scss"; // Make sure you have this file

const cardHover = {
  hover: {
    scale: 1.02,
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
    transition: { duration: 0.3 },
  },
};


const ServerCard = ({ product }) => {
  return (
    <motion.article
      className={styles.solutionsCard}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.4 }}
      whileHover="hover"
      variants={cardHover}
    >
      <div className={styles.solutionsCardTopRow}>
        <h3 className={styles.solutionsCardTitle}>
          {(() => {
            const processor = product.customdata?.processor || "";
            const parenIndex = processor.indexOf("(");
            if (parenIndex > -1) {
              const namePart = processor.substring(0, parenIndex).trim();
              const detailsPart = processor.substring(parenIndex).trim();
              return (
                <>
                  {namePart}
                  <br />
                  <span style={{ fontSize: '0.9em', color: '#666' }}>{detailsPart}</span>
                </>
              );
            }
            return processor;
          })()}
        </h3>
        <p className={styles.solutionsCardInstantDeploy}>Instant Deploy</p>
      </div>

      <h4 className={styles.solutionsCardPrice}>
        {(product.pricing?.USD?.monthly || product.price) === "Custom Pricing"
          ? (product.pricing?.USD?.monthly || product.price)
          : `$${product.pricing?.USD?.monthly || product.price}`}
      </h4>

      <hr />

      <a
        className={styles.orderNow}
        href={product.product_url}
        aria-label={`Order ${product.customdata?.processor}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Order Now
      </a>

      <div className={styles.solutionsCardBottomRow}>
        <div>
          <p className={styles.solutionsCardIncludes}>Includes:</p>
          <ul className={styles.solutionsList}>
            <li>{product.customdata?.memory}</li>
            <li>{product.customdata?.storage}</li>
            <li>{product.customdata?.network}</li>
            <li>{product.customdata?.ips}</li>
            {product.customdata?.gpu && product.customdata?.gpu.toLowerCase() !== "none" && (
              <li>{product.customdata?.gpu}</li>
            )}
          </ul>
        </div>
      </div>
    </motion.article>
  );
};

export default ServerCard;
