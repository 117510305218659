import React from "react";
import { Helmet } from "react-helmet";
import styles from "./AllDedicatedServersPage.module.scss";

// Import Components
import HeroComponent from "../../Layout/Components/HeroComponent/HeroComponent";
import ServerTypeIntro from "../../Components/ServerTypeIntro/ServerTypeIntro";

// Import Assets
import StandardServerGroup from "../../Assets/Standard_Server.png";
import CustomServerGroup from "../../Assets/Custom_Server.png";
import GPU_Enabled_Server_Group from "../../Assets/AI_Capable_Server.png";
import StorageServerGroup from "../../Assets/Storage_Server.png";

// Import Animations
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

const AllDedicatedServersPage = () => {
  return (
    <div>
      {/* SEO Metadata */}
      <Helmet>
        <title>All Dedicated Servers | WebNX</title>
        <meta
          name="description"
          content="Explore WebNX's range of dedicated servers, including standard, custom-built, GPU-enabled, and storage solutions. Tailored server configurations for every need."
        />
        <meta
          name="keywords"
          content="dedicated servers, custom servers, GPU-enabled servers, storage servers, WebNX hosting solutions"
        />
        <meta
          property="og:title"
          content="All Dedicated Servers | WebNX"
        />
        <meta
          property="og:description"
          content="Discover WebNX's tailored dedicated servers: standard, custom-built, GPU-enabled, and storage servers for your business needs."
        />
        <meta property="og:image" content="https://webnx.com/assets/dedicated-servers.jpg" />
        <link rel="canonical" href="https://webnx.com/all-dedicated-servers" />
      </Helmet>

      {/* Hero Section */}
      <HeroComponent
        title="All Dedicated Servers"
        subtitle="Learn About Our <br /> Dedicated Servers"
        buttonText1="Shop Servers Now"
        buttonLink1="#filterComponent"
        showButton1={false}
        buttonText2="More Information"
        buttonLink2="#moreInfo"
        showButton2={false}
      />

      {/* Standard Dedicated Servers Section */}
      <FadeInOnScroll>
        <ServerTypeIntro
          header="Standard <br/> Dedicated Servers"
          subheader="Dedicated Servers"
          text="WebNX offers high-performance standard dedicated servers, providing unparalleled control, security, and reliability for your business needs."
          image={StandardServerGroup}
          showButton={true}
          buttonText="Learn More"
          buttonLink="/standard-dedicated-servers"
        />
      </FadeInOnScroll>

      {/* Custom Dedicated Servers Section */}
      <FadeInOnScroll>
        <ServerTypeIntro
          header="Custom <br />Dedicated Servers"
          subheader="Tailored Solutions to Fit Every Unique Need"
          text="WebNX's custom-built dedicated servers are designed to meet your exact requirements, providing optimized performance for demanding workloads."
          image={CustomServerGroup}
          showButton={true}
          buttonText="Learn More"
          buttonLink="/custom-dedicated-servers"
          reverse={true}
        />
      </FadeInOnScroll>

      {/* GPU-Enabled Dedicated Servers Section */}
      <FadeInOnScroll>
        <ServerTypeIntro
          header="GPU Enabled<br /> Dedicated Servers"
          subheader="Harness the Power of GPU-Accelerated Computing"
          text="Accelerate your applications with WebNX's GPU-enabled dedicated servers, perfect for AI, machine learning, rendering, and scientific computations."
          image={GPU_Enabled_Server_Group}
          showButton={true}
          buttonText="Learn More"
          buttonLink="/gpu-enabled-servers"
        />
      </FadeInOnScroll>

      {/* Storage Dedicated Servers Section */}
      <FadeInOnScroll>
        <ServerTypeIntro
          header="Storage <br /> Dedicated Servers"
          subheader="Scalable Storage Solutions"
          text="WebNX's storage servers provide scalable, secure solutions for managing high-volume data, backups, and media storage for businesses of all sizes."
          image={StorageServerGroup}
          showButton={true}
          buttonText="Learn More"
          buttonLink="/storage-dedicated-servers"
          reverse={true}
        />
      </FadeInOnScroll>
    </div>
  );
};

export default AllDedicatedServersPage;
