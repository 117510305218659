import React from 'react';
import { Helmet } from 'react-helmet';

// Import SCSS
import styles from './ServiceLevelAgreementPage.module.scss';

// Import Components
import ServiceLevelAgreementComponent from './Components/ServiceLevelAgreementComponent/ServiceLevelAgreementComponent';

const ServiceLevelAgreementPage = () => {
  return (
    <div className={styles.serviceLevelAgreementPage}>
      {/* React Helmet for SEO */}
      <Helmet>
        <title>Service Level Agreement | WebNX</title>
        <meta
          name="description"
          content="Learn about WebNX's Service Level Agreement (SLA), including our 100% network uptime guarantee, hardware replacement policies, and SLA credit terms."
        />
        <meta
          name="keywords"
          content="WebNX SLA, Service Level Agreement, 100% Uptime, Power Guarantee, Hardware Replacement Policy"
        />
        <meta name="author" content="WebNX" />
        <meta
          property="og:title"
          content="Service Level Agreement - WebNX Legal Information"
        />
        <meta
          property="og:description"
          content="WebNX provides a transparent Service Level Agreement (SLA) that guarantees 100% uptime, power reliability, and robust hardware support."
        />
        <meta
          property="og:url"
          content="https://webnx.com/service-level-agreement"
        />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://webnx.com/service-level-agreement" />
      </Helmet>

      <main>
        <ServiceLevelAgreementComponent />
      </main>
    </div>
  );
};

export default ServiceLevelAgreementPage;
