// Import Libraries
import React from "react";
import { Helmet } from "react-helmet";

// Import SCSS
import styles from "./GpuEnabledServersPage.module.scss";

// Import Components
import ServerPageIntro from "../../Components/ServerPageIntro/ServerPageIntro";
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import ServerStandardsComponent from "../../Components/ServerStandardsComponent/ServerStandardsComponent";
import DedicatedServerLocations from "../DedicatedServersPage/Components/DedicatedServerLocations/DedicatedServerLocations";
import TestNetworkSpeedsComponent from "../DedicatedServersPage/Components/TestNetworkSpeedsComponent/TestNetworkSpeedsComponent";

// Import Assets
import GPU_Enabled_Server from "../../Assets/AI_Capable_Server.png";

// Import Animations
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

const GpuEnabledServersPage = () => {
  return (
    <div>
      {/* Helmet for Metadata */}
      <Helmet>
        <title>GPU Enabled Servers | High-Performance GPU Hosting by WebNX</title>
        <meta
          name="description"
          content="Discover WebNX GPU-enabled dedicated servers for AI, deep learning, and scientific computing. High-performance GPU servers for unparalleled acceleration."
        />
        <meta
          name="keywords"
          content="GPU enabled servers, GPU dedicated servers, AI servers, deep learning servers, scientific computing, WebNX GPU hosting"
        />
        <meta property="og:title" content="GPU Enabled Servers | High-Performance GPU Hosting" />
        <meta
          property="og:description"
          content="Unleash the power of GPU-optimized servers for AI, deep learning, and scientific applications with WebNX GPU hosting solutions."
        />
        <meta property="og:image" content="https://webnx.com/assets/gpu-server.jpg" />
        <meta property="og:url" content="https://webnx.com/gpu-enabled-servers" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webnx.com/gpu-enabled-servers" />
      </Helmet>

      <FadeInOnScroll>
        <ServerPageIntro
          header="WebNX GPU-Enabled Servers"
          subheader="Accelerate Your Compute-Intensive Workloads"
          introText="Power through demanding workloads with our GPU-enabled dedicated servers. Featuring Intel Xeon processors and NVIDIA GPUs with up to 3,500 CUDA cores, these servers are specifically designed for AI, deep learning, and scientific computing. Whether you need the GTX 1080TI or a custom configuration, our team will build the perfect solution for your computational needs."
          image={GPU_Enabled_Server}
          hideImageOnMobile={true}
          features={[
            {
              icon: "FaMicrochip",
              title: "Latest GPUs",
              description: "NVIDIA RTX and Tesla GPUs for maximum computational power"
            },
            {
              icon: "FaBrain",
              title: "AI/ML Optimized",
              description: "Pre-configured for popular AI and ML frameworks with CUDA support"
            },
            {
              icon: "FaBolt",
              title: "High Performance",
              description: "Dual Intel Xeon processors and high-speed NVMe storage"
            },
            {
              icon: "FaChartLine",
              title: "Scalable Solutions",
              description: "Multi-GPU configurations available for enterprise workloads"
            }
          ]}
        />
      </FadeInOnScroll>

      {/* Filter Component */}
      <FilterComponent defaultFilters={{ "Server Type": ["GPU Enabled"] }} />

      <FadeInOnScroll>
        <ServerStandardsComponent />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <DedicatedServerLocations />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <TestNetworkSpeedsComponent />
      </FadeInOnScroll>
    </div>
  );
};

export default GpuEnabledServersPage;
