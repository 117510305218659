// Import Libraries
import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

// Import Components
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import ServerStandardsComponent from "../../Components/ServerStandardsComponent/ServerStandardsComponent";
import DedicatedServerLocations from "./Components/DedicatedServerLocations/DedicatedServerLocations";
import TestNetworkSpeedsComponent from "./Components/TestNetworkSpeedsComponent/TestNetworkSpeedsComponent";
import ServerPageIntro from "../../Components/ServerPageIntro/ServerPageIntro";

// Import Assets
import StandardServer from "../../Assets/Standard_Server.png";

// Import Animation
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

const DedicatedServersPage = () => {
  const filterRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#filterComponent" && filterRef.current) {
      filterRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>Standard Dedicated Servers | High-Performance Dedicated Server Hosting</title>
        <meta
          name="description"
          content="Discover WebNX's high-performance dedicated servers. Offering custom-built configurations, 24/7 support, and optimized networks tailored for your needs."
        />
        <meta
          name="keywords"
          content="dedicated servers, custom server hosting, WebNX hosting, high-performance servers, server colocation"
        />
        <meta
          property="og:title"
          content="WebNX | High-Performance Dedicated Servers"
        />
        <meta
          property="og:description"
          content="Explore WebNX's dedicated server solutions, including GPU-enabled servers, custom configurations, and enterprise-grade hosting."
        />
        <meta
          property="og:url"
          content="https://webnx.com/dedicated-servers"
        />
        <meta
          property="og:image"
          content="https://webnx.com/assets/dedicated-servers.jpg"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webnx.com/dedicated-servers" />
      </Helmet>

      <FadeInOnScroll>
        <ServerPageIntro
          header="WebNX Dedicated Servers"
          subheader="High-Performance Bare Metal Solutions"
          introText="Experience unmatched performance with WebNX's custom-built dedicated servers. We offer a comprehensive range of configurations to suit any need, from single to quad CPU servers. With premium hardware from industry leaders like SuperMicro, Intel, and AMD, combined with our High-Performance BGP Route Optimized networks, you get the perfect balance of power and reliability."
          image={StandardServer}
          hideImageOnMobile={true}
          features={[
            {
              icon: "FaServer",
              title: "Enterprise Hardware",
              description: "Premium Intel and AMD processors with ECC memory for maximum reliability"
            },
            {
              icon: "FaNetworkWired",
              title: "High-Speed Network",
              description: "10Gbps network with DDoS protection and multiple carrier options"
            },
            {
              icon: "FaShieldAlt",
              title: "Security First",
              description: "Advanced firewall, IPMI access, and 24/7 security monitoring"
            },
            {
              icon: "FaClock",
              title: "Rapid Deployment",
              description: "Same-day setup with custom OS installation and configuration"
            }
          ]}
        />
      </FadeInOnScroll>

      <div id="filterComponent" ref={filterRef}>
        <FilterComponent defaultFilters={{ "Server Type": ["Standard"] }} />
      </div>

      <FadeInOnScroll>
        <ServerStandardsComponent />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <DedicatedServerLocations />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <TestNetworkSpeedsComponent />
      </FadeInOnScroll>
    </div>
  );
};

export default DedicatedServersPage;
