import React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import styles from "./ServerTypeIntro.module.scss";

const ServerTypeIntro = ({
  header,
  subheader,
  text,
  image,
  showButton,
  buttonText,
  buttonLink,
  reverse,
}) => {
  // Sanitize the incoming HTML
  const cleanHeader = DOMPurify.sanitize(header);
  const cleanSubheader = DOMPurify.sanitize(subheader);
  const cleanText = DOMPurify.sanitize(text);

  return (
    <section className={styles.container}>
      <div className={styles.dedicatedServersContainer}>
        <div className={`${styles.contentWrapper} ${reverse ? styles.reverse : ''}`}>
          <div className={styles.textSection}>
            <h2
              className={styles.dedicatedServersHeader}
              dangerouslySetInnerHTML={{ __html: cleanHeader }}
            />
            <h3
              className={styles.dedicatedServersSubHeader}
              dangerouslySetInnerHTML={{ __html: cleanSubheader }}
            />
            <p
              className={styles.dedicatedServersText}
              dangerouslySetInnerHTML={{ __html: cleanText }}
            />
            {showButton && (
              <a
                href={buttonLink}
                rel="noopener noreferrer"
                className={styles.button}
                aria-label={buttonText}
              >
                {buttonText}
              </a>
            )}
          </div>
          <div className={styles.imageSection}>
            <img
              className={styles.serverImage}
              src={image}
              alt={header.replace(/<br\s*\/?>/gi, " ")}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

ServerTypeIntro.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  reverse: PropTypes.bool,
};

ServerTypeIntro.defaultProps = {
  showButton: false,
  buttonText: "Learn More",
  buttonLink: "#",
  reverse: false,
};

export default ServerTypeIntro; 