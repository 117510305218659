// Import Libraries
import React from "react";

// Import SCSS
import styles from "./WhyChooseUsComponent.module.scss";

//Import Assets
import BurstablePortIcon from "../../../../Assets/icons/BurstablePort.svg";
import PowerIcon from "../../../../Assets/icons/PowerIcon.svg";
import BatteryIcon from "../../../../Assets/icons/BatteryIcon.svg";
import GraphsIcon from "../../../../Assets/icons/GraphsIcon.svg";

const WhyChooseUsComponent = () => {
  const features = [
    {
      icon: BurstablePortIcon,
      title: "100mbps Fully Burstable Port",
      description: "High-performance network connectivity with burst capabilities"
    },
    {
      icon: PowerIcon,
      title: "Remote Rebooter",
      description: "24/7 remote server management and reboot capabilities"
    },
    {
      icon: BatteryIcon,
      title: "1 Amp 120v Power",
      description: "Reliable power supply with backup systems"
    },
    {
      icon: GraphsIcon,
      title: "Bandwidth Graphs",
      description: "Real-time monitoring and analytics dashboard"
    },
    {
      number: "5",
      title: "5 Useable IP Addresses",
      description: "Dedicated IP allocation for your services"
    }
  ];

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.textContent}>
            <h2 className={styles.title}>
              Included In All <br /> Locations
            </h2>
            <h3 className={styles.subtitle}>Included Features</h3>
            <p className={styles.description}>
              All WebNX colocation plans come with key features designed to
              provide reliable performance, seamless server management, and the
              flexibility your business requires.
            </p>
          </div>
          <div className={styles.featuresGrid}>
            {features.map((feature, index) => (
              <div key={index} className={styles.featureCard}>
                {feature.icon ? (
                  <img src={feature.icon} alt={feature.title} className={styles.icon} />
                ) : (
                  <div className={styles.number}>{feature.number}</div>
                )}
                <h4 className={styles.featureTitle}>{feature.title}</h4>
                <p className={styles.featureDescription}>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsComponent;
