import React from "react";
import { motion } from "framer-motion";
import styles from "./ServerStandardsComponent.module.scss";

// Import Assets
import Wifi from "../../Assets/icons/Wifi-Icon-Plain.svg";
import BellIcon from "../../Assets/icons/Bell-Icon-Plain.svg";
import ShieldIcon from "../../Assets/icons/Shield-Icon-Plain.svg";
import BandwidthIcon from "../../Assets/icons/Bandwidth-Icon-Plain.svg";
import NetworkIcon from "../../Assets/icons/Hardware-Icon-Plain.svg";
import PowerIcon from "../../Assets/icons/SLA-Icon-Plain.svg";

// Define animations
const containerAnimation = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      staggerChildren: 0.2
    }
  }
};

const cardAnimation = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0 },
  hover: {
    scale: 1.02,
    boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
    transition: { duration: 0.2 }
  }
};

const ServerStandardsComponent = ({
  title = "Server Standards",
  subtitle = "Included With All Dedicated Servers",
  description = "WebNX servers are designed for top performance and reliability. With 24x7x365 on-site support, high-speed network ports, flexible bandwidth options, advanced monitoring, and premium hardware from Intel and AMD, we provide the security and control your business needs.",
  standards = [
    {
      icon: Wifi,
      title: "24x7x365 On-Site & Network Support",
      description: "All WebNX Data Centers are staffed 24 hours a day, 7 days a week, 365 days a year. We offer support for network and hardware issues by phone and our client portal."
    },
    {
      icon: BellIcon,
      title: "High-Quality Hardware",
      description: "Our servers feature components from industry leaders such as Intel, AMD, SuperMicro, and SLI, ensuring maximum performance and reliability."
    },
    {
      icon: BandwidthIcon,
      title: "Flexible Bandwidth",
      description: "Select from 20TB on a 1Gbps port or a 100Mbps dedicated unmetered option. Need more? We offer extensive upgrade options to meet your needs."
    },
    {
      icon: ShieldIcon,
      title: "Advanced Monitoring and Control",
      description: "Monitor your bandwidth usage and perform remote reboots directly from the WebNX Client Portal. Most servers include IPMI access for BIOS-level control."
    },
    {
      icon: NetworkIcon,
      title: "1Gbps Public and Private Network",
      description: "High-speed connections with private and public ports. Our private network links your servers, allowing for unmetered transfers between them without using your public bandwidth."
    }
  ],
  containerId = "moreInformation"
}) => {
  return (
    <div className={styles.container} id={containerId}>
      <div className={styles.titleSection}>
        <h2>{title}</h2>
        <div className={styles.divider} />
        <h3>{subtitle}</h3>
        <p>{description}</p>
      </div>

      <motion.div 
        className={styles.contentWrapper}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={containerAnimation}
      >
        <div className={styles.cardsSection}>
          {standards.map((standard, index) => (
            <motion.div key={index} className={styles.card} variants={cardAnimation} whileHover="hover">
              <div className={styles.iconWrapper}>
                <img src={standard.icon} alt={standard.title} className={styles.icon} />
              </div>
              <div className={styles.cardContent}>
                <h4>{standard.title}</h4>
                <p>{standard.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default ServerStandardsComponent;
