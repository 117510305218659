// Import Libraries
import React from "react";

// Import SCSS
import styles from "./ReviewsComponent.module.scss";

// Import Icons
import { FaStar } from 'react-icons/fa';

const ReviewsComponent = () => {
  const reviews = [
    {
      name: "Lee Miles",
      rating: 5,
      text: "We have benefited from 3 years amazing VOIP phone system and super-fast and reliable internet service from WebNX. We changed providers for the dramatic cost savings, but their reliability and service have made all of the difference. Their technicians both internal and external are extremely reliable and so willing to help in any way a non-tech business owner can impose on them. I would recommend them to anyone!!",
      date: "Verified Customer"
    },
    {
      name: "Samuel Draney",
      rating: 5,
      text: "Unbelievably quick service and quality hardware.",
      date: "Verified Customer"
    },
    {
      name: "Bee",
      rating: 5,
      text: "Great looking data center. Friendly staff. Custom build servers to your needs",
      date: "Verified Customer"
    }
  ];

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.textContent}>
            <h2 className={styles.title}>
              What Our Customers Say
            </h2>
            <h3 className={styles.subtitle}>Customer Reviews</h3>
            <p className={styles.description}>
              Read what our valued customers have to say about their experience with WebNX. We're proud to provide exceptional service and support to businesses worldwide.
            </p>
          </div>
          <div className={styles.reviewsGrid}>
            {reviews.map((review, index) => (
              <div key={index} className={styles.reviewCard}>
                <div className={styles.reviewHeader}>
                  <div className={styles.stars}>
                    {[...Array(review.rating)].map((_, i) => (
                      <FaStar key={i} className={styles.star} />
                    ))}
                  </div>
                  <div className={styles.reviewerInfo}>
                    <h4 className={styles.reviewerName}>{review.name}</h4>
                    <span className={styles.reviewDate}>{review.date}</span>
                  </div>
                </div>
                <p className={styles.reviewText}>{review.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewsComponent; 