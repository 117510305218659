// Import Libraries
import React from 'react';

// Import SCSS
import styles from './CallToAction.module.scss';

// Import Assets
import CtaPattern from '../../../Assets/cta-pattern.svg';

const CallToAction = () => {
  return (
    <div className={styles.container}>
      <div className={styles.pattern}>
        <img src={CtaPattern} alt="" aria-hidden="true" />
      </div>
      <div className={styles.content}>
        <h1 className={styles.heading}>
          Unleash The Power Of WebNX
        </h1>
        <p className={styles.subtext}>
          Experience enterprise-grade hosting solutions tailored for your success
        </p>
        <a href="/standard-dedicated-servers" className={styles.ctaButton}>
          Browse Servers
          <span className={styles.buttonGlow}></span>
        </a>
      </div>
    </div>
  );
};

export default CallToAction;