import React, { useEffect, useState } from "react";
import { getProducts } from "../../ApiLayer/apiLayer";
import styles from "./FilterComponent.module.scss";
import LoaderSpinner from "../../util/LoadingComponent/LoaderSpinner";
import Pagination from "./Components/Pagination/Pagination";
import ProductDisplayComponent from "./Components/ProductDisplayComponent/ProductDisplayComponent";
import ProductDisplayHeaderComponent from "./Components/ProductDisplayHeaderComponent/ProductDisplayHeaderComponent";
import FilterModal from "./Components/FilterModal/FilterModal";
import FilterIcon from "../../Assets/icons/Filter.svg";

const FilterComponent = ({ defaultFilters = {} }) => {
  // State variables
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productData, setProductData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [priceRange, setPriceRange] = useState([0, 1000]); // Default price range
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  // Initialize selectedFilters with default values or empty Sets
  const [selectedFilters, setSelectedFilters] = useState({
    Location: new Set(defaultFilters.Location || []),
    "Server Type": new Set(defaultFilters["Server Type"] || []),
    Brand: new Set(defaultFilters.Brand || []),
    Model: new Set(defaultFilters.Model || []),
    "Drive Type": new Set(),
    "Drive Capacity": new Set()
  });

  // Filter options
  const filterOptions = {
    Location: ["Ogden, UT", "Manhattan, NYC", "Los Angeles, CA"],
    "Server Type": ["Standard", "GPU Enabled", "Storage"],
    Brand: ["AMD", "INTEL"],
    Model: {
      AMD: ["Ryzen", "Threadripper", "EPYC"],
      INTEL: ["Xeon"]
    },
    "Drive Type": ["SSD", "NVMe", "HDD"],
    "Drive Capacity": ["250GB", "500GB", "1TB", "2TB", "4TB", "8TB", "16TB"]
  };

  const locationMapping = {
    "Ogden, UT": "Datacenter - Ogden, Utah",
    "Manhattan, NYC": "Datacenter - Manhattan, New York",
    "Los Angeles, CA": "Datacenter - Los Angeles, California",
  };

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getProducts();

        if (response.products) {
          setProductData(response.products);
          setFilteredData(response.products);
          
          // Set initial price range based on actual product prices
          const prices = response.products.map(p => parseFloat(p.pricing.USD.monthly));
          const minPrice = Math.floor(Math.min(...prices));
          const maxPrice = Math.ceil(Math.max(...prices));
          setPriceRange([minPrice, maxPrice]);
        } else {
          setError("No Products Found");
        }
      } catch (error) {
        console.error("Error:", error);
        setError("Failed to Fetch Product Data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Reapply filters when product data or selected filters change
  useEffect(() => {
    if (productData.length > 0) {
      applyFilters(selectedFilters);
    }
  }, [productData, selectedFilters, priceRange]);

  // Handle filter option selection
  const handleFilterToggle = (filterType, filterValue) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      const filterSet = new Set(updatedFilters[filterType]);

      if (filterSet.has(filterValue)) {
        filterSet.delete(filterValue);
      } else {
        filterSet.add(filterValue);
      }

      updatedFilters[filterType] = filterSet;
      return updatedFilters;
    });
  };

  // Apply filters based on selected filters
  const applyFilters = (filters) => {
    let filteredProducts = productData;

    // Filter by price range
    filteredProducts = filteredProducts.filter((product) => {
      const price = parseFloat(product.pricing.USD.monthly);
      return price >= priceRange[0] && price <= priceRange[1];
    });

    // Filter by location
    if (filters.Location.size > 0) {
      filteredProducts = filteredProducts.filter((product) => {
        const productLocation = product.customdata.location?.toLowerCase().trim();
        return Array.from(filters.Location).some((loc) => {
          const mappedLocation = locationMapping[loc]?.toLowerCase().trim();
          return mappedLocation === productLocation;
        });
      });
    }

    // Filter by brand
    if (filters.Brand.size > 0) {
      filteredProducts = filteredProducts.filter((product) => {
        const productBrand = product.customdata.brandname?.toUpperCase().trim();
        return filters.Brand.has(productBrand);
      });
    }

    // Filter by model (considering brand dependency)
    if (filters.Model.size > 0) {
      filteredProducts = filteredProducts.filter((product) => {
        const productModel = product.customdata.model?.toLowerCase().trim();
        return Array.from(filters.Model).some(
          (model) => model.toLowerCase() === productModel
        );
      });
    }

    // Filter by drive type
    if (filters["Drive Type"].size > 0) {
      filteredProducts = filteredProducts.filter((product) => {
        const storage = product.customdata.storage?.toLowerCase().trim() || "";
        return Array.from(filters["Drive Type"]).some(
          (type) => storage.includes(type.toLowerCase())
        );
      });
    }

    // Filter by drive capacity
    if (filters["Drive Capacity"].size > 0) {
      filteredProducts = filteredProducts.filter((product) => {
        const storage = product.customdata.storage?.toLowerCase().trim() || "";
        return Array.from(filters["Drive Capacity"]).some(
          (capacity) => {
            const capacityValue = capacity.toLowerCase();
            // Handle cases like "2 x 1TB" or "1.92TB"
            const storageMatches = storage.match(/\d+(?:\.\d+)?\s*(?:x\s*)?\d*(?:\.\d+)?\s*[tg]b/g) || [];
            return storageMatches.some(match => match.includes(capacityValue));
          }
        );
      });
    }

    // Filter by server type
    if (filters["Server Type"].size > 0) {
      filteredProducts = filteredProducts.filter((product) => {
        const serverType = product.customdata.type?.toLowerCase().trim();
        return Array.from(filters["Server Type"]).some(
          (type) => type.toLowerCase() === serverType
        );
      });
    }

    setFilteredData(filteredProducts);
    setCurrentPage(1);
  };

  // Clear all filters
  const clearAllFilters = () => {
    setSelectedFilters({
      Location: new Set(),
      Brand: new Set(),
      "Server Type": new Set(),
      Model: new Set(),
      "Drive Type": new Set(),
      "Drive Capacity": new Set()
    });
    
    // Reset price range to initial values
    const prices = productData.map(p => parseFloat(p.pricing.USD.monthly));
    setPriceRange([Math.floor(Math.min(...prices)), Math.ceil(Math.max(...prices))]);
    
    setFilteredData(productData);
    setCurrentPage(1);
  };

  // Pagination logic
  const totalPages = Math.ceil(filteredData.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Get min and max prices from product data
  const prices = productData.map(p => parseFloat(p.pricing.USD.monthly));
  const minPrice = Math.floor(Math.min(...prices));
  const maxPrice = Math.ceil(Math.max(...prices));

  // Render active filters
  const renderActiveFilters = () => {
    const activeFilters = [];

    // Add price range if it's not at min/max
    if (priceRange[0] > minPrice || priceRange[1] < maxPrice) {
      activeFilters.push(
        <div key="price" className={styles.activeFilterGroup}>
          <p className={styles.activeFilterHeader}>Price Range:</p>
          <span className={styles.activeFilter}>
            ${priceRange[0]} - ${priceRange[1]}
          </span>
        </div>
      );
    }

    // Add other filters
    Object.keys(selectedFilters).forEach((filterType) => {
      const activeFilterValues = Array.from(selectedFilters[filterType]);
      if (activeFilterValues.length > 0) {
        activeFilters.push(
          <div key={filterType} className={styles.activeFilterGroup}>
            <p className={styles.activeFilterHeader}>{filterType}:</p>
            {activeFilterValues.map((filter, index) => (
              <span key={`${filterType}-${index}`} className={styles.activeFilter}>
                {filter}
              </span>
            ))}
          </div>
        );
      }
    });

    return activeFilters.length > 0 ? activeFilters : (
      <div className={styles.noActiveFilters}>
        No filters applied - showing all products
      </div>
    );
  };

  // Add this function to handle records per page change
  const handleRecordsPerPageChange = (newValue) => {
    setRecordsPerPage(newValue);
    setCurrentPage(1); // Reset to first page when changing records per page
  };

  return (
    <div className={styles.container} id="filterComponent">
      <div className={styles.titleSection}>
        <h2>Available Servers</h2>
        <p>Browse our selection of high-performance dedicated servers. Use the filters below to find the perfect configuration for your needs.</p>
      </div>
      <div className={styles.mainContent}>
        <div className={styles.filterHeader}>
          <button className={styles.filterButton} onClick={() => setIsModalOpen(true)}>
            <img src={FilterIcon} alt="Filter" />
            <span>Filters</span>
          </button>
          <div className={styles.activeFiltersContainer}>
            <div className={styles.activeFilters}>
              {renderActiveFilters()}
            </div>
            {renderActiveFilters().length > 0 && (
              <p className={styles.clearAll} onClick={clearAllFilters}>
                CLEAR ALL
              </p>
            )}
          </div>
        </div>

        <FilterModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          filterOptions={filterOptions}
          selectedFilters={selectedFilters}
          handleFilterToggle={handleFilterToggle}
          priceRange={priceRange}
          setPriceRange={setPriceRange}
          minPrice={minPrice}
          maxPrice={maxPrice}
        />

        <div className={styles.tableWrapper}>
          <div className={styles.tableContainer}>
            <ProductDisplayHeaderComponent />

            {loading ? (
              <div className={styles.loaderRow}>
                <LoaderSpinner loading={loading} />
              </div>
            ) : error ? (
              <div className={styles.errorRow}>{error}</div>
            ) : currentRecords.length > 0 ? (
              currentRecords.map((product) => (
                <div key={product.pid} className={styles.flexRow}>
                  <div className={styles.flexCell}>
                    {(() => {
                      const processor = product.customdata.processor || "";
                      const parenIndex = processor.indexOf("(");
                      if (parenIndex > -1) {
                        const namePart = processor.substring(0, parenIndex).trim();
                        const detailsPart = processor.substring(parenIndex).trim();
                        return (
                          <>
                            {namePart}
                            <br />
                            <span style={{ fontSize: '0.9em', color: '#666' }}>{detailsPart}</span>
                          </>
                        );
                      }
                      return processor;
                    })()}
                  </div>
                  <div className={styles.flexCell}>{product.customdata.memory}</div>
                  <div className={styles.flexCell}>{product.customdata.storage}</div>
                  <div className={styles.flexCell}>{product.customdata.network}</div>
                  <div className={styles.flexCell}>{product.customdata.ips}</div>
                  <div className={styles.flexCell}>{product.customdata.gpu}</div>
                  <div className={styles.flexCell}>
                    <span className={styles.priceContainer}>
                      ${product.pricing.USD.monthly}
                    </span>
                  </div>
                  <div className={styles.flexCell}>
                    <a
                      className={styles.orderNowButton}
                      href={product.product_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Order Now
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noDataRow}>No products available.</div>
            )}
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          recordsPerPage={recordsPerPage}
          onRecordsPerPageChange={handleRecordsPerPageChange}
        />
      </div>
    </div>
  );
};

export default FilterComponent;
