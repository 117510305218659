import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

// Component Imports
import TopHeader from "./Components/TopHeader/TopHeader";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import CallToAction from "./Components/CallToAction/CallToAction";

// Import Styles
import styles from './Layout.module.scss';

const Layout = (props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  // Pages without hero component
  const pagesWithoutHero = [
    '/standard-dedicated-servers',
    '/error',
    '/gpu-enabled-servers',
    '/storage-servers',
    '/custom-dedicated-servers',
    '/datacenter-locations-and-networks/utah',
    '/datacenter-locations-and-networks/california',
    '/datacenter-locations-and-networks/newyork',
    '/colocation',
    '/contact',
    '/service-level-agreement',
    '/terms-of-service',
    '/about',
    '/server-upgrades'
    // Add any other pages that don't use HeroComponent
  ];

  const hasHero = !pagesWithoutHero.includes(location.pathname);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className={styles.layoutContainer}>
      <Header
        isMobileMenuOpen={isMobileMenuOpen}
        toggleMobileMenu={toggleMobileMenu}
        hasHero={hasHero}
      />

      {/* Dark overlay with transition, shown when mobile menu is open */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            className={styles.overlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          />
        )}
      </AnimatePresence>

      <main>{props.children ?? <Outlet />}</main>
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Layout;
