import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./DedicatedServerLocations.module.scss";

// Import Assets
import Utah from "../../../../Assets/icons/Utah.svg";
import NewYork from "../../../../Assets/icons/NewYork.svg";
import California from "../../../../Assets/icons/California.svg";
import { FaMapMarkerAlt, FaArrowRight } from 'react-icons/fa';

const DedicatedServerLocations = () => {
  const [selectedLocation, setSelectedLocation] = useState("california");

  const locationData = {
    california: {
      name: "Los Angeles, CA",
      icon: California,
      link: "/datacenter-locations-and-networks/california",
      description: [
        "Looking for new Los Angeles Dedicated Servers? Do you need a fast connection to the Asia-Pacific region? Look no further. All WebNX Los Angeles Dedicated Servers utilize the WebNX High-Performance BGP Route Optimized network.",
        "The WebNX High-Performance BGP Route Optimized network offers the best possible routing to all locations. By using the Noction IRP and direct uplinks to 11 different carriers, we offer real-time BGP route optimization to all our clients."
      ]
    },
    utah: {
      name: "Ogden, UT",
      icon: Utah,
      link: "/datacenter-locations-and-networks/utah",
      description: [
        "Are you looking for a new, low-cost, dedicated server? Do you need a fast and reliable internet connection at an affordable price? Look no further. The WebNX Ogden Utah Dedicated Servers offer a terrific blend of performance and value.",
        "By using the Noction IRP and direct uplinks to 6 different carriers, we offer real-time BGP route optimization to all our clients. We optimize our paths continually to provide the best available routes."
      ]
    },
    newyork: {
      name: "Manhattan, NY",
      icon: NewYork,
      link: "/datacenter-locations-and-networks/newyork",
      description: [
        "Are you looking for new East Coast dedicated servers? Do you need a fast and reliable internet connection in the heart of Manhattan, NYC? WebNX offers enterprise-quality hardware and network at affordable prices.",
        "All NYC Dedicated Servers utilize the WebNX High-Performance BGP Route Optimized Network. We use the Noction IRP and direct uplinks to 3 different carriers to offer real-time BGP route optimization to all our clients."
      ]
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const contentVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 }
  };

  return (
    <div className={styles.background}>
      <div className={styles.dedicatedServersContainer}>
        <div className={styles.dedicatedServersLeft}>
          <h1 className={styles.dedicatedServersHeader}>
            WebNX Dedicated <br /> Server Locations
          </h1>
          <div className={styles.locationHeader}>
            <FaMapMarkerAlt className={styles.locationIcon} />
            <h2 className={styles.dedicatedServersSubHeader}>
              {locationData[selectedLocation].name}
            </h2>
          </div>

          <AnimatePresence mode="wait">
            <motion.div
              key={selectedLocation}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={contentVariants}
              transition={{ duration: 0.5 }}
              className={styles.dedicatedServersText}
            >
              {locationData[selectedLocation].description.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
              <motion.a
                className={styles.dedicatedServersButton}
                href={locationData[selectedLocation].link}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Learn More <FaArrowRight className={styles.buttonIcon} />
              </motion.a>
            </motion.div>
          </AnimatePresence>
        </div>

        <motion.div 
          className={styles.statesContainer}
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {Object.entries(locationData).map(([key, location]) => (
            <motion.img
              key={key}
              src={location.icon}
              alt={`${location.name} Dedicated Server Location`}
              className={selectedLocation === key ? styles.activeImage : ''}
              onClick={() => setSelectedLocation(key)}
              whileHover={{ scale: 1.05 }}
              animate={selectedLocation === key ? {
                rotate: 2,
                scale: 1.05,
                transition: { duration: 0.3 }
              } : {
                rotate: 0,
                scale: 1,
                transition: { duration: 0.3 }
              }}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default DedicatedServerLocations;
