// Import Libraries
import React from "react";

// Import Styles
import styles from "./ColocationPage.module.scss";

// Import Components
import ServerPageIntro from "../../Components/ServerPageIntro/ServerPageIntro";

// Import Animations
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

// import Assets
import ColocationImage from "../../Assets/colocationImage.png";
import ColocationQuoteForm from "./Components/ColocationQuoteForm/ColocationQuoteForm";
import ColocationPricing from "./Components/ColocationPricing/ColocationPricing";
import ColocationInfo from "./Components/ColcoationInfo/ColocationInfo";

const ColocationPage = () => {
  return (
    <>
      <FadeInOnScroll>
        <ServerPageIntro
          header="Enterprise-Grade Colocation Services"
          subheader="Scalable & Secure Data Center Solutions"
          introText="WebNX provides secure, high-performance colocation in world-class data centers with full N+1 redundancy, diverse power feeds, and direct connectivity to top carriers. From single servers to private cages, our scalable solutions ensure low latency, high uptime, and cost-effective performance."
          image={ColocationImage}
          hideImageOnMobile={true}
          features={[
            {
              icon: "FaShieldAlt",
              title: "High-Security Facilities",
              description: "24/7 monitoring, biometric access, and redundant power for maximum security."
            },
            {
              icon: "FaNetworkWired",
              title: "Redundant Networking",
              description: "Carrier-neutral connectivity with multi-100Gbps uplinks for low-latency routing."
            },
            {
              icon: "FaBolt",
              title: "N+1 Power Redundancy",
              description: "Diverse power feeds with UPS and generator backup to prevent downtime."
            },
            {
              icon: "FaServer",
              title: "Scalable Colocation Options",
              description: "From 1U servers to full private cages, built for flexibility and growth."
            },
            {
              icon: "FaChartLine",
              title: "99.99% Uptime SLA",
              description: "High-availability infrastructure with real-time monitoring and performance guarantees."
            },
            {
              icon: "FaDatabase",
              title: "Direct Cloud & Carrier Access",
              description: "Seamless connectivity to AWS, Google Cloud, Azure, and leading carriers."
            }
          ]}
        />

      </FadeInOnScroll>

      <ColocationPricing />
      <ColocationInfo />
    </>
  );
};

export default ColocationPage;
