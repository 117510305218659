// Import Libraries
import React from 'react';
import { 
  FaServer, 
  FaNetworkWired, 
  FaHeadset, 
  FaBuilding, 
  FaChartLine, 
  FaShieldAlt 
} from 'react-icons/fa';

// Import SCSS
import styles from './WhyChooseUsComponent.module.scss';

const WhyChooseUsComponent = () => {
  const features = [
    {
      icon: FaServer,
      title: "Custom Solutions",
      description: "Tailored server configurations and hosting solutions designed to meet your specific business needs."
    },
    {
      icon: FaNetworkWired,
      title: "High Performance Networks",
      description: "Enterprise-grade network infrastructure with optimized routing and multiple carrier options."
    },
    {
      icon: FaHeadset,
      title: "24/7/365 Support",
      description: "Round-the-clock technical support from our experienced team of professionals."
    },
    {
      icon: FaBuilding,
      title: "State of the Art Datacenters",
      description: "Modern, secure facilities with redundant power and cooling systems."
    },
    {
      icon: FaChartLine,
      title: "Scalable Solutions",
      description: "Flexible infrastructure that grows with your business needs."
    },
    {
      icon: FaShieldAlt,
      title: "Proven Reliability",
      description: "Industry-leading uptime guarantees and robust security measures."
    }
  ];

  return (
    <section className={styles.section} aria-labelledby="why-choose-us">
      {/* SEO Metadata */}
      <meta
        name="description"
        content="Discover why businesses choose WebNX for hosting solutions. Custom-built solutions, high-performance networks, and 24/7/365 support make us stand out."
      />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.textContent}>
            <h2 id="why-choose-us" className={styles.title}>
              Why Choose <br /> Us?
            </h2>
            <h3 className={styles.subtitle}>WebNX Guarantee</h3>
            <p className={styles.description}>
              WebNX goes beyond standard hosting by offering custom-built solutions tailored to your unique needs. Here's why clients choose us.
            </p>
          </div>
          <div className={styles.featuresGrid}>
            {features.map((feature, index) => (
              <div key={index} className={styles.featureCard}>
                <div className={styles.iconWrapper}>
                  <feature.icon className={styles.icon} />
                </div>
                <h4 className={styles.featureTitle}>{feature.title}</h4>
                <p className={styles.featureDescription}>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsComponent;
